import { Component } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractListController } from 'src/app/admin/common/abstract-list.component';
import { PARAM_PERSONAL_DATA_PATH } from 'src/app/admin/constants/constants';
import { Personal_data } from 'src/app/models/personal_data';
import { PersonalDataService } from 'src/app/services/personal_data.service';

@Component({
  selector: 'app-manage-personal-data',
  templateUrl: './manage-personal-data.component.html'
})
export class ManagePersonalDataComponent extends AbstractListController<Personal_data, any> {
  title = 'Dati personali';
  nameFilter: string = '';
  descriptionFilter: string = '';
  cols: any[];
  columnOptions: any[];
  selectedColumns: any[];
  
  constructor(
    protected sersonalDataService: PersonalDataService,
    protected confirmationService: ConfirmationService,
    router: Router,
    route: ActivatedRoute
  ) {
    super(sersonalDataService, confirmationService, router, route, PARAM_PERSONAL_DATA_PATH);

    this.cols = [
      { field: 'name', header: 'Nome', index: 1 },
      { field: 'description', header: 'Descrizione', index: 2 }
    ];

    this.columnOptions = [];
    this.selectedColumns = [];
    for (let i = 0; i < this.cols.length; i++) {
      this.columnOptions.push({ label: this.cols[i].header, value: this.cols[i] });
    }
  }
  

  
  getFilterParams(): any {
    return {
      name: this.nameFilter,
      description: this.descriptionFilter
    };
  }

  applyFilterParams(params: any): void {
    this.nameFilter = params['name'] || '';
    this.descriptionFilter = params['description'] || '';
  }

  
  filterItem(item: Personal_data, filters: any): boolean {
    const nameMatch = !filters.name || item.name.toLowerCase().includes(filters.name.toLowerCase());
    const descriptionMatch = !filters.description || item.description.toLowerCase().includes(filters.description.toLowerCase());
    return nameMatch && descriptionMatch;
  }

  clearFilters(): void {
    this.nameFilter = '';
    this.descriptionFilter = '';
  }
}
