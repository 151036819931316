import { Component, OnInit, ViewChild } from "@angular/core";
import { Blog } from "../../models/blog";
import {
  FormGroup,
} from "@angular/forms";
import { DASHBOARD, PAGES, TYPE_LIST } from "../constants/constants";
import { Category } from "src/app/models/category";
import { Clients } from "src/app/models/clients";
import { Appointments } from "src/app/models/appointments";
import { AppointmentsService } from "src/app/services/appointments.service";
import { formatDate } from "@angular/common";
import { Locations } from "src/app/models/locations";
import { Employees } from "src/app/models/employees";
import { WorksService } from "src/app/services/works.service";
import { Works } from "src/app/models/works";
import * as moment from "moment";
import { ProductsService } from "src/app/services/products.service";
import { Products } from "src/app/models/products";
import { Billings } from "src/app/models/billings";
import { NgxSpinnerService } from "ngx-spinner";
import "moment/locale/it"; // without this line it didn't work
import { Supports } from "src/app/models/supports";
import { ProjectsService } from "src/app/services/projects.service";
import { Projects } from "src/app/models/projects";
import { environment } from "src/environments/environment";
import { forkJoin, map, switchMap } from "rxjs";
import { ProductsImagesService } from "src/app/services/products_images.service";
import { ClientsService } from "src/app/services/clients.service";
import { RequestsService } from "src/app/services/requets.service";
import { Requests } from "src/app/models/requests";

moment.locale("it");

@Component({
  selector: "app-admin-dashboard",
  templateUrl: "./admin-dashboard.component.html",
})
export class AdminDashboardComponent implements OnInit {
  calendarOptions: any;
  events: any;
  title = 'Dashboard';
  appointments: any = [];
  appointment: Appointments;
  appointmentsToday: any = [];
  appointmentsTomorrow: any = [];

  locations: any = [];
  location: Locations;

  employees: any = [];
  employee: Employees;
  works: any = [];
  work: Works;
  clientsCount: any;
  supports: any = [];
  support: Supports;

  products: any = [];
  product: Products;
  productsCount: any;
  error: string;
  blogForm: FormGroup;
  typeList: any;
  cities: Blog[];
  format1: string = "";
  format2: string = "";
  selectedCity: Blog;
  uploadError: string;
  selectedCategories: Category;
  selectedDate: Date;
  date: Date;
  selectedWorks: any[];
  categories: any = [];
  category: Category;
  productDialog: boolean = false;
  appointmentsDialog: boolean = false;
  clients: any = [];
  client: Clients;
  comuni: any = [];
  displayEvent: any;
  currentUser: any;
  requests: Requests[] = [];
  items: any;
  projects: any = [];
  project: Projects;
  billingsCountTotalNotPaid: Billings;
  category_id: string;
  data1 = [];
  count = 0;
  pageSize = 3;
  page = 1;
  dateTomorrow: any;
  imgPath = environment.imageUrl;

  trackByFn(index, item) {
    return item.id;
  }

  myMonth = formatDate(new Date(), "dd/MM/yyyy", "en");
  nameFilter: string;
  descriptionFilter: string;
  dateFromFilter: string;
  dateToFilter: string;
  currentDate: moment.Moment = moment();
  currentTime: string = moment().format(" MMMM YYYY");

  @ViewChild("mychart") mychart;

  constructor(
    private appointmentsService: AppointmentsService,
    private productsImagesService: ProductsImagesService,
    private clientService: ClientsService,
    private projectsService: ProjectsService,
    private requestsService: RequestsService,
    private worksService: WorksService,
    private productsService: ProductsService,
  ) {
    this.typeList = TYPE_LIST;
    this.currentUser = JSON.parse(localStorage.getItem("currentUser") || "[]");
    this.events = this.appointments;
    this.items = DASHBOARD;
  }

  ngOnInit() {
    this.getProducts();

    this.getAppointmentsToday();
    this.getAppointmentsTomorrow();
    this.getProjects();
    this.getRequests();
  }

  getProjects() {
    const paramsProjects = {
      page: 0,
      size: 2,
    };
    this.projectsService.list(paramsProjects).subscribe((pData) => {
      this.projects = pData;
      this.count = this.projectsService.size;
    });
  }


  getRequests() {
    const paramsRequests = {
      page: 0,
      size: 6,
    };
    this.requestsService.list(paramsRequests).subscribe((pData) => {
      this.requests = pData;
      this.count = this.projectsService.size;
    });
  }

  getProducts() {
    const paramsProducts = {
      page: 0,
      size: 2,
      is_active: 1,
    };
    this.productsService.list(paramsProducts).subscribe((pData) => {
      this.products = pData;
      this.count = this.projectsService.size;
    });
  }

  getWorks() {
    const paramsWorks = []
    this.worksService.list(paramsWorks).subscribe((pData) => {
      this.works = pData;
      (error) => (this.error = error);
    });
  }


  getAppointmentsToday() {
    const paramsToday = {
      page: 0,
      size: 2,
      dateFrom: formatDate(new Date(), "yyyy-MM-dd 00:00", "en"),
      dateTo: formatDate(new Date(), "yyyy-MM-dd 23:59", "en"),
    };

    this.appointmentsService.list(paramsToday).subscribe((pData) => {
      this.appointmentsToday = pData;
    });
  }

  getAppointmentsTomorrow() {
    var currentDate = new Date();
    this.dateTomorrow = currentDate.setDate(currentDate.getDate() + 1);

    const paramsTomorrow = {
      page: 0,
      size: 2,
      dateFrom: formatDate(this.dateTomorrow, "yyyy-MM-dd 00:00", "en"),
      dateTo: formatDate(this.dateTomorrow, "yyyy-MM-dd 23:59", "en"),
    };

    this.appointmentsService.list(paramsTomorrow).subscribe((pData) => {
      this.appointmentsTomorrow = pData;
    });
  }

  getWorksItem(works_id: string, id: string) {
    return this.works.find((item) => item.id === works_id);
  }

  editProduct(appointment: Appointments) {
    this.appointment = { ...appointment };
    this.getWorks();


      this.clientService.getId(+this.appointment.category_id).subscribe((res) => {
        this.client = res;
      });

      this.selectedWorks = this.appointment.works_id.split(',');


    this.appointmentsDialog = true;
  }

  showDialog() {
    this.productDialog = true;
  }

  clickButton(model: any) {
    this.displayEvent = model;
  }
  eventClick(model: any) {
    model = {
      event: {
        id: model.event.id,
        start: model.event.start,
        title: model.event.title,
        works_id: model.event.works_id.split(","),
        location_id: model.event.location_id,
        employee_id: model.event.employee_id,
        allDay: model.event.allDay,
        description: model.event.description,
        category_id: model.event.category_id,
        client: model.event.client,

        // other params
      },
      duration: {},
    };
    this.displayEvent = model;
    this.productDialog = true;
  }

  eventRender(event) {
    const html = `<div>
      <div><i class="pi pi-clock"></i> ${event.event.time}</div>
      <hr classs="small">
      <div><strong>${event.event.client.name}</strong></div>
    <div><strong>${event.event.client.surname}</strong></div>

    </div>`;
    event.element.html(html);
  }

  dayClick(event) {
    console.log("dayClick", event);
  }
}
