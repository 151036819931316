import { Directive, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService } from 'primeng/api';
import { CrudService } from 'src/app/services/crud.service';

@Directive()
export abstract class AbstractListController<T, ID> implements OnInit {
  items: T[] = [];
  filteredItems: T[] = [];
  item: T;
  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [5, 10, 15];
  loading = false;
  error: string;

  private loadingRequest = false; // To prevent multiple requests

  constructor(
    protected service: CrudService<T, ID>,
    protected confirmationService: ConfirmationService,
    protected router: Router,
    protected route: ActivatedRoute,
    public path: string
  ) {}

  ngOnInit(): void {
    this.initializeFromUrl();
  }

  abstract getFilterParams(): any;
  abstract applyFilterParams(params: any): void;
  abstract filterItem(item: T, filters: any): boolean;
  abstract clearFilters(): void;


  
  
  load(): void {
    if (this.loadingRequest) return; // Prevent multiple requests
    this.loadingRequest = true;

    const params = this.getRequestParams(this.page, this.pageSize);

    this.service.list(params).subscribe({
      next: (data) => {
        this.items = data;
        this.count = this.service.size;
        this.applyFilters(this.getFilterParams());
        this.loading = false;
        this.loadingRequest = false; // Reset loading state
      },
      error: (err) => {
        this.error = err;
        this.loading = false;
        this.loadingRequest = false; // Reset loading state
      }
    });

    this.updateUrlParams(params);
  }


  loadItems(): void {


    if (this.loadingRequest) return; // Prevent multiple requests
    this.loadingRequest = true;

    const params = this.getRequestParams(this.page, this.pageSize);

    this.service.list(params).subscribe({
      next: (data) => {
        this.items = data;
        this.count = this.service.size;
        this.applyFilters(this.getFilterParams());
        this.loading = false;
        this.loadingRequest = false; // Reset loading state
      },
      error: (err) => {
        this.error = err;
        this.loading = false;
        this.loadingRequest = false; // Reset loading state
      }
    });

    this.updateUrlParams(params);
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.loadItems();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.loadItems();
  }

  resetFilters(): void {
    this.clearFilters();
    this.page = 1;
    this.loadItems();
  }

  reset(): void {
    this.clearFilters();
    this.page = 1;
    this.loadItems();
  }

  deleteItem(id: ID): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this item?',
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.service.delete(+id).subscribe({
          next: () => this.loadItems(),
          error: (err) => this.error = err
        });
      }
    });
  }

  onDelete(id: ID): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this item?',
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.service.delete(+id).subscribe({
          next: () => this.loadItems(),
          error: (err) => this.error = err
        });
      }
    });
  }


  protected applyFilters(filters: any): void {
    this.filteredItems = this.items.filter(item => this.filterItem(item, filters));
    this.paginate();
  }

  protected paginate(): void {
    this.filteredItems = this.filteredItems.slice(
      (this.page - 1) * this.pageSize,
      this.page * this.pageSize
    );
  }

  protected getRequestParams(page: number, pageSize: number): any {
    return {
      page: page - 1,
      size: pageSize,
      ...this.getFilterParams()
    };
  }

  protected updateUrlParams(params: any): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        page: this.page,
        size: this.pageSize,
        ...this.getFilterParams()
      },
      queryParamsHandling: 'merge' // Merge with existing query params
    });
  }

  protected initializeFromUrl(): void {
    this.route.queryParams.subscribe(params => {
      this.page = +params['page'] || 1;
      this.pageSize = +params['size'] || 10;
      this.applyFilterParams(params); // Apply filter params from the URL
      this.loadItems();
    });
  }
}
