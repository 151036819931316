import { Component, ElementRef, OnInit } from "@angular/core";
import { AppointmentsService } from "../../../services/appointments.service";
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
  UntypedFormArray,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ViewChild } from "@angular/core";
import { Blog } from "../../../models/blog";
import { Category } from "../../../models/category";
import { UntypedFormControl } from "@angular/forms";
import { CategoryService } from "../../../services/categories.service";
import { ConfirmationService, MessageService, SelectItem } from "primeng/api";
import * as moment from "moment";
import {
  TYPE_LIST,
  STATUS_PRODUCTS,
  STATE_LIST,
  NUMBER_LIST,
  PRODUCT_TYPE_LIST,
} from "../../constants/constants";
import { Clients } from "src/app/models/clients";
import { ClientsService } from "src/app/services/clients.service";
import { Location } from "@angular/common";
import { WorksService } from "src/app/services/works.service";
import { Works } from "src/app/models/works";
import { EmployeesService } from "src/app/services/employees.service";
import { Employees } from "src/app/models/employees";
import { Locations } from "src/app/models/locations";
import { LocationsService } from "src/app/services/locations.service";
import { Appointments } from "src/app/models/appointments";
import { SumPipe } from "../../pipe/sum.pipe";
import { ProductsService } from "src/app/services/products.service";
import { Products } from "src/app/models/products";
import { Brand } from "src/app/models/brand";
import { BrandService } from "src/app/services/brands.service";
import { TagsService } from "src/app/services/tags.service";
import { Tags } from "src/app/models/tags";
import { SkillsService } from "src/app/services/skills.service";
import { map, tap } from "rxjs/operators";
import { Technical_data } from "src/app/models/technical_data";
import { TechnicalDataService } from "src/app/services/technical_data.service";
import { GoogleMap, MapInfoWindow, MapMarker } from "@angular/google-maps";
import { ComuniService } from "src/app/services/comuni.service";
import { Comuni } from "src/app/models/comuni";
import { Observable } from "rxjs";
import { HttpEventType, HttpResponse } from "@angular/common/http";
import { ProductsImagesService } from "src/app/services/products_images.service";
import { environment } from "src/environments/environment";

export interface fPairs {
  qty: number;
  price: number;
}

@Component({
  selector: "app-products-form",
  templateUrl: "./products-form.component.html",
})
export class ProductsFormComponent {
  @ViewChild("myInput", { static: false }) myInputVariable: ElementRef;
  zoom: string;
  pageTitle: string;
  error: string;
  uploadError: string;
  imagePath: any;
  blogs: Blog;
  blog: Blog;
  id: number;
  appointments: Appointments;
  appointment: any;
  clientsPoints: any;
  data1: any = [];
  categories: any = [];
  category: Category;
  element: Products
  works: any = [];
  work: Works;

  checked: boolean = true;
  selectedValue: string;
  products: any = [];
  product: Products;
  blogForm: UntypedFormGroup;
  typeList: any[];
  productTypeList: any[];
  numberList: any[];

  status: any[];
  stateOptions: any[];

  clients: any = [];
  client: Clients;
  arrString: string;
  comuni: any = [];
  technical_datas: any = [];
  technical_data: Technical_data;

  tags: any = [];

  description: any;
  selectedWorks: SelectItem[] = [];
  selectedSkills: SelectItem[] = [];
  selectedWorks2: SelectItem[];
  selectedCategories: SelectItem[] = [];

  locations: any = [];
  location: Locations;

  cities: Blog[];
  format1: string = "";
  format2: string = "";
  selectedCity: Blog;
  selectedClients: SelectItem[];
  marker: any;
  selectedDate: Date;
  date: Date;
  works_id: any;
  category_id: any;
  public dataValues: object;
  pages: any;
  currentUser: any;
  fPairs: any;
  addForm: UntypedFormGroup;
  rows: UntypedFormArray;
  itemForm: UntypedFormGroup;
  skillsForm: UntypedFormGroup;
  mapOptions: any = [];
  infoContent = "";
  skillsValues: any = [];
  cols: any[];
  colsData: any[];
  productTitle: string;
  trackByFn(index, item) {
    return item.id;
  }

  imgPath = environment.imageUrl;

  
  center: any;
  selectedFiles?: FileList;
  progressInfos: any[] = [];
  message: string[] = [];
  markerOptions: google.maps.MarkerOptions = { draggable: true };
  markerPositions: google.maps.LatLngLiteral[] = [];
  previews: string[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private appointmentsService: AppointmentsService,
    private technicalDataService: TechnicalDataService,
    private messageService: MessageService,
    private clientsService: ClientsService,
    private _location: Location,
    private productsService: ProductsService,
    private productsImagesService: ProductsImagesService,
    private skillsService: SkillsService,
    private worksService: WorksService,
    private categoryService: CategoryService,
    private comuniService: ComuniService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private tagsService: TagsService,
    private route: ActivatedRoute
  ) {
    if (this.date) {
      this.selectedDate = new Date(this.date);
    }
    this.typeList = TYPE_LIST;
    this.numberList = NUMBER_LIST;
    this.productTypeList = PRODUCT_TYPE_LIST;
    this.status = STATUS_PRODUCTS;
    this.stateOptions = STATE_LIST;


    this.getselectedWorks;
    this.getselectedCategories;

    const paramsTechnical = [];
    this.technicalDataService.list(paramsTechnical).subscribe((pData) => {
      this.technical_datas = pData;
      (error) => (this.error = error);
    });

    
    const paramsWorks = [];
    this.worksService.list(paramsWorks).subscribe((pData) => {
      this.works = pData;
      (error) => (this.error = error);
    });

    const paramsCategories = [];
    this.categoryService.list(paramsCategories).subscribe((pData) => {
      this.categories = pData;
      (error) => (this.error = error);
    });

    const paramsTags = [];
    this.tagsService.list(paramsTags).subscribe((pData) => {
      this.tags = pData;
      (error) => (this.error = error);
    });

    const id = this.route.snapshot.paramMap.get("id");

    if (id) {
      this.pageTitle = "Modifica Immobile";

      this.productsService.getId(+id).subscribe((res) => {
        this.element = res

        this.loadComuni(res.location_id);

        this.blogForm.patchValue({
          title: res.title,
          description: res.description.split(","),
          category_id: res.category_id,
          status: res.status,
          works_id: res.works_id.split(","),
          rooms: res.rooms,
          floors: res.floors,
          bathrooms: res.bathrooms,
          is_featured: res.is_featured,
          is_active: res.is_active,
          code: res.code,
          location_id: res.location_id,
          type_id: res.type_id,
          user_id: res.user_id,
          description_full: res.description_full,
          mq: res.mq,
          price: res.price,
          price_extra: res.price_extra,
          lat: res.lat,
          lng: res.lng,
          id: res.id,
          data: res.data,
          skills: this.skillsValues,
        });

        this.id = res.id;
        this.productTitle = res.title;

        this.mapOptions = {
          streetViewControl: false,
          mapTypeControl: false,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          scaleControl: false,
          disableDoubleClickZoom: true,
          zoom: 14,
        };

        this.center = {
          lat: parseFloat(res.lat),
          lng: parseFloat(res.lng),
        };

        this.marker = {
          lat: parseFloat(res.lat),
          lng: parseFloat(res.lng),
          draggable: true,
        };
      });
    } else {
      this.pageTitle = "Aggiungi Immobile";
    }

    this.blogForm = this.fb.group({
      id: [""],
      title: ["", Validators.required],
      description: [""],
      description_full: [""],
      is_featured: ["0"],
      category_id: [""],
      status: [""],
      works_id: [""],
      rooms: [""],
      floors: [""],
      bathrooms: [""],
      mq: [""],
      is_active: ["0"],
      image: [""],
      code: [""],
      location_id: [""],
      type_id: [""],
      user_id: [""],
      price: [""],
      price_extra: [""],
      lat: [""],
      lng: [""],
      skills: this.initSkill(),
    });
  }



  onSearch(event: any) {
    const query = event.filter;
    
    // Only trigger search if query is 3 or more characters long
    if (query && query.length >= 3) {
      this.searchComuni(query);
    }
  }
  
  searchComuni(query: string) {
    const paramsSearchComuni = {
      name: query,
    };
  
    this.comuniService.list(paramsSearchComuni).subscribe((pData) => {
      this.comuni = pData;  // Update the options in the dropdown
    });
  }

  loadComuni(query: string) {

    const paramsComuni = {
      id: query,
      page: 0,
      size: 10,
      };

    this.comuniService.list(paramsComuni).subscribe((pData) => {
      this.comuni = pData;
      (error) => (this.error = error);
    });
  };

  initSkill() {
    var formArray = this.fb.array([]);
    const id = this.route.snapshot.paramMap.get("id");

    const findParam = {
      field: "skills",
    };

    this.productsService.find(+id, findParam).subscribe((res) => {
      this.skillsValues = res;

      this.skillsValues.forEach((e) => {
        formArray.push(
          this.fb.group({
            qty: [e.qty],
            price: [e.price],
          })
        );
      });
    });

    /*formArray.push(this.fb.group({
      qty: [''],
      price: ['']
    })) */

    return formArray;
  }

  private createSkillFormGroup(skill: any): UntypedFormGroup {
    return new UntypedFormGroup({
      qty: new UntypedFormControl(skill.qty),
      price: new UntypedFormControl(skill.price),
    });
  }

  public addSkill(skill: any) {
    this.skills.push(this.createSkillFormGroup(skill));
  }

  get skills() {
    return this.blogForm.get("skills") as UntypedFormArray;
  }

  newQuantity(): UntypedFormGroup {
    return this.fb.group({
      qty: "",
      price: "",
    });
  }

  addQuantity() {
    this.skills.push(this.newQuantity());
  }

  removeQuantity(i: number) {
    this.skills.removeAt(i);
  }

  getWorksItem(works_id: string, id: string) {
    return this.works.find((item) => item.id === works_id);
  }

  getselectedWorks() {
    this.selectedWorks = this.works_id.split(",");
  }

  getCategoryItem(category_id: string, id: string) {
    return this.categories.find((item) => item.id === category_id);
  }

  getselectedCategories() {
    this.selectedCategories = this.category_id.split(",");
  }

  goback() {
    this._location.back();
  }

 
  
  onSubmit() {
    const formData = new FormData();

    formData.append("title", this.blogForm.get("title").value);
    formData.append("description", this.blogForm.get("description").value);
    formData.append(
      "description_full",
      this.blogForm.get("description_full").value
    );
    formData.append("is_featured", this.blogForm.get("is_featured").value);
    formData.append("category_id", this.blogForm.get("category_id").value);
    formData.append("works_id", this.blogForm.get("works_id").value);
    formData.append("rooms", this.blogForm.get("rooms").value);
    formData.append("floors", this.blogForm.get("floors").value);
    formData.append("bathrooms", this.blogForm.get("bathrooms").value);
    formData.append("mq", this.blogForm.get("mq").value);
    formData.append("location_id", this.blogForm.get("location_id").value);
    formData.append("type_id", this.blogForm.get("type_id").value);
    formData.append("is_active", this.blogForm.get("is_active").value);
    formData.append("code", this.blogForm.get("code").value);
    formData.append("price", this.blogForm.get("price").value);
    formData.append("price_extra", this.blogForm.get("price_extra").value);
    formData.append("lat", this.blogForm.get("lat").value);
    formData.append("lng", this.blogForm.get("lng").value);
    formData.append("status", this.blogForm.get("status").value);
    formData.append("user_id", this.blogForm.get("user_id").value);
    formData.append(
      "skills",
      JSON.stringify(this.blogForm.get("skills").value)
    );

    const id = this.blogForm.get("id").value;

    if (id) {
      this.productsService.update(formData, +id).subscribe(
        (res) => {
          if (res.status == "error") {
            this.uploadError = res.message;
          } else {
            this.messageService.add({
              key: "myKey1",
              severity: "success",
              summary: "Informazioni",
              detail: "Salvato con sucesso",
            });
            //this.router.navigate(['/admin/products']);
          }
        },
        (error) => (this.error = error)
      );
    } else {
      this.productsService.create(formData).subscribe(
        (res) => {
          if (res.status === "error") {
            this.uploadError = res.message;
          } else {
            this.messageService.add({
              key: "myKey1",
              severity: "success",
              summary: "Informazioni",
              detail: "Salvato con sucesso",
            });
            this.router.navigate(["/admin/products"]);
          }
        },
        (error) => (this.error = error)
      );
    }
  }
}
