import { Component } from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { Router, ActivatedRoute } from "@angular/router";
import { AbstractListController } from "src/app/admin/common/abstract-list.component";
import { Appointments } from "src/app/models/appointments";
import { AppointmentsService } from "src/app/services/appointments.service";

import { ClientsService } from "src/app/services/clients.service";
import { Clients } from "src/app/models/clients";
import { Billings } from "src/app/models/billings";
import { BillingsService } from "src/app/services/billings.service";
import { Comuni } from "src/app/models/comuni";
import { PARAM_COMUNI_PATH } from "src/app/admin/constants/constants";
import { ComuniService } from "src/app/services/comuni.service";

@Component({
  selector: 'app-manage-comuni',
  templateUrl: './manage-comuni.component.html'
})
export class ManageComuniComponent extends AbstractListController<
  Comuni,
  any
> {
  title = "Fatture";
  nameFilter: string = "";
  regionFilter: string = "";
  cols: any[];
  columnOptions: any[];
  selectedColumns: any[];
  productDialog: boolean = false;
  appointment: Appointments;
  billing: Billings;
  selectedSkills: any[];

  constructor(
    protected comuniService: ComuniService,
    private messageService: MessageService,
    protected confirmationService: ConfirmationService,
    router: Router,
    route: ActivatedRoute
  ) {
    super(
      comuniService,
      confirmationService,
      router,
      route,
      PARAM_COMUNI_PATH
    );

    this.cols = [
      { field: "name", header: "Nome" },
      { field: "region_name", header: "Regione" },
    ];

    this.columnOptions = [];
    this.selectedColumns = [];
    for (let i = 0; i < this.cols.length; i++) {
      this.columnOptions.push({
        label: this.cols[i].header,
        value: this.cols[i],
      });
    }
  }

  getFilterParams(): any {
    return {
      name: this.nameFilter,
      region_name: this.regionFilter,
    };
  }

  applyFilterParams(params: any): void {
    this.nameFilter = params["name"] || "";
    this.regionFilter = params["region_name"] || "";

  }

  filterItem(item: Comuni, filters: any): boolean {
    const nameMatch =
      !filters.date ||
      item.name.toLowerCase().includes(filters.date.toLowerCase());
    const regionMatch =
      !filters.region ||
      item.region
        .toLowerCase()
        .includes(filters.description.toLowerCase());
    return nameMatch && regionMatch;
  }

  clearFilters(): void {
    this.nameFilter = "";
    this.regionFilter = "";
  }

  deactive(billing: Billings) {
    const formData = new FormData();
   
    formData.set("is_active", "0");
    this.comuniService.update(formData, billing.id).subscribe(
      (res) => {
        this.load();
        this.messageService.add({
          key: "myKey1",
          severity: "success",
          summary: "Attenzione",
          detail: "Aggiornato con success",
        });
      },
      (error) => (this.error = error)
    );
  }

  active(billing: Billings) {
    const formData = new FormData();
   
    formData.set("is_active", "1");

    this.comuniService.update(formData, billing.id).subscribe(
      (res) => {
        this.load();
        this.messageService.add({
          key: "myKey1",
          severity: "success",
          summary: "Attenzione",
          detail: "Aggiornato con success",
        });
      },
      (error) => (this.error = error)
    );
  }

  edit(billing: Billings) {
    this.billing = { ...billing };
    this.selectedSkills = JSON.parse("" + this.billing.skills + "");
    this.productDialog = true;
  }
}

