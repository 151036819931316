import { Component, OnInit, Input, AfterViewInit } from "@angular/core";

@Component({
  selector: "app-appointment-detail",
  templateUrl: "./appointment-detail.component.html"
})
export class AppointmentDetailComponent implements OnInit {
  @Input() appointment: any;
  @Input() client: any;
  @Input() selectedWorks: any;
  @Input() works: any;

  constructor() {}

  ngOnInit() {}



  getWorksItem(works_id: string, id: string) {
    return this.works.find(item => item.id === works_id);
  }


 
}
