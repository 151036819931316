import { HttpEventType, HttpResponse } from "@angular/common/http";
import { Component, OnInit, Input, AfterViewInit, ViewChild, ElementRef } from "@angular/core";
import { FormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { Observable } from "rxjs";
import { ApiResponse } from "src/app/models/api-response.";
import { Products } from "src/app/models/products";
import { Products_images } from "src/app/models/products_images";
import { ProductsImagesService } from "src/app/services/products_images.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-upload",
  templateUrl: "./upload.component.html",
  styleUrls: ["./upload.component.scss"]
})
export class UploadComponent implements OnInit {

  @Input() element: any;
  @Input() product: any;

  @ViewChild("myInput", { static: false }) myInputVariable: ElementRef;
  blogForm: FormGroup;
  images: any;
  error: string;
  selectedFiles?: FileList;
  progressInfos: any[] = [];
  message: string[] = [];
  previews: string[] = [];
  uploadError: string;
  cols: any[];
  path = environment.imageUrl;
  imagePath: any;
  count: 0;
  imageInfos: Products_images[] = [];
  id: number;

  constructor(
    private fb: UntypedFormBuilder,
    private messageService: MessageService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private productsImagesService: ProductsImagesService
  ) {}

  ngOnInit(): void {
    if (this.product) {
      this.productsImagesService.list({ product_id: this.product }).subscribe((pData: Products_images[]) => {
        this.imageInfos = pData;
      });
    } else {
      console.error(this.product);
    }
  }

  selectFiles(event: any): void {
    this.message = [];
    this.progressInfos = [];
    this.selectedFiles = event.target.files;
    this.previews = [];

    if (this.selectedFiles && this.selectedFiles[0]) {
      const numberOfFiles = this.selectedFiles.length;
      for (let i = 0; i < numberOfFiles; i++) {
        const reader = new FileReader();

        reader.onload = (e: any) => {
          this.previews.push(e.target.result);
        };

        reader.readAsDataURL(this.selectedFiles[i]);
      }
    }
  }

  upload(idx: number, file: File): void {
    this.progressInfos[idx] = { value: 0, fileName: file.name };

    if (file) {
      const id = this.route.snapshot.paramMap.get("id");

      this.productsImagesService.upload(file, this.product).subscribe({
        next: (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progressInfos[idx].value = Math.round(
              (100 * event.loaded) / event.total
            );
          } else if (event instanceof HttpResponse) {
            const msg = "Uploaded the file successfully: " + file.name;
            this.message.push(msg);

            this.productsImagesService.list({ product_id: this.product }).subscribe((pData: Products_images[]) => {
              this.imageInfos = pData;
            });

            this.previews = [];
            this.progressInfos = [];
            this.resetFileInput(); // Reset file input after upload
            setTimeout(() => {
              this.message = [];
            }, 1500);
          }
        },
        error: (err: any) => {
          this.progressInfos[idx].value = 0;
          const msg = "Could not upload the file: " + file.name;
          this.message.push(msg);
        },
      });
    }
  }

  uploadFiles(): void {
    this.message = [];

    if (this.selectedFiles) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        this.upload(i, this.selectedFiles[i]);
      }
    }
  }

  resetFileInput(): void {
    // Clear the file input and selected files
    this.selectedFiles = undefined;
    if (this.myInputVariable && this.myInputVariable.nativeElement) {
      this.myInputVariable.nativeElement.value = "";
    }
  }

  onDeleteImage(image_id: any, image_name: any, page_id: any) {
    this.confirmationService.confirm({
      message: `Sei sicuro di volerlo cancellare = ${image_name}`,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.productsImagesService.delete(+image_id).subscribe(
          (res: ApiResponse) => {
            if (res.status === "error") {
              this.messageService.add({
                key: "myKeyUpload",
                severity: "error",
                summary: "Errore",
                detail: `Cancellazione non riuscita: ${res.message || 'Errore sconosciuto'}`,
              });
            } else {
              this.messageService.add({
                key: "myKeyUpload",
                severity: "success",
                summary: "Attenzione",
                detail: "Cancellazione avvenuta con successo",
              });

              this.productsImagesService.list({ product_id: this.product }).subscribe(
                (pData: Products_images[]) => {
                  this.imageInfos = pData;
                },
                (error) => {
                  this.error = error;
                  this.messageService.add({
                    key: "myKeyUpload",
                    severity: "error",
                    summary: "Errore",
                    detail: "Impossibile aggiornare l'elenco delle immagini",
                  });
                }
              );
            }
          },
          (error) => {
            this.messageService.add({
              key: "myKeyUpload",
              severity: "error",
              summary: "Errore",
              detail: "Impossibile completare la cancellazione",
            });
          }
        );
      },
    });
  }

  onRowEditSave(image: any) {
    const formData = new FormData();
    formData.append("description", image.description);
    formData.append("image_order", image.image_order);

    const id = image.id;

    this.productsImagesService.update(formData, +id).subscribe(
      (res) => {
        if (res.status == "error") {
          this.messageService.add({
            key: "myKey1",
            severity: "warn",
            summary: "Informazioni",
            detail: "Errore",
          });
          this.uploadError = res.message;
        } else {
          this.messageService.add({ key: 'myKey1', severity: 'success', summary: 'Attenzione', detail: 'Salvato con sucesso' });
          this.productsImagesService.list({ product_id: this.element.id }).subscribe((pData) => {
            this.imageInfos = pData;
          });
        }
      },
      (error) => (this.error = error)
    );
  }

  onRowEditInit(image: any) {
    this.imageInfos[image.image_id] = { ...image };
  }
}
