import { SelectItem } from "primeng/api";

import { environment } from "../../../environments/environment";
export const APP_API = "api/v1/";


export const CLIENTS_API = environment.baseUrl + APP_API + "locations";
export const APPOINTMENTS_API = environment.baseUrl + APP_API + "appointments";
export const LOCATIONS_API = environment.baseUrl + APP_API + "locations";





export const PARAM_WORKS_PATH = '/admin/settings/works/'
export const PARAM_CATEGORIES_PATH = '/admin/settings/categories/'
export const PARAM_LOCATIONS_TYPE_PATH = '/admin/settings/locations/'
export const PARAM_COMUNI_PATH = '/admin/settings/comuni/'


export const PARAM_TAGS_PATH = '/admin/settings/tags/'
export const PARAM_PERSONAL_DATA_PATH = '/admin/settings/personal-data/'
export const PARAM_TYPE_PATH = '/admin/settings/type/'

export const PARAM_BRANDS_PATH = '/admin/settings/brands/'

export const PARAM_TECHNICAL_DATA_PATH = '/admin/settings/technical-data/'
export const PARAM_WAREHOUSES_PATH = '/admin/settings/warehouses/'
export const PARAM_CHECKINS_PATH = '/admin/checkins/'

export const PARAM_WAREHOUSE_MOVEMENTS_PATH = '/admin/warehouse-movements/'

export const PARAM_CHECKOUTS_PATH = '/admin/checkouts/'
export const PARAM_TIMESHEETS_PATH = '/admin/timesheets/'
export const PARAM_BILLINGS_PATH = '/admin/billings/'
export const PARAM_PURCHASE_ORDERS_PATH = '/admin/purchase-orders/'

export const PARAM_TRANSPORT_DOCUMENTS_PATH = '/admin/transport-documents/'
export const PARAM_QUOTES_PATH = '/admin/quotes/'
export const PARAM_ORDERS_PATH = '/admin/orders/'
export const PARAM_DOCUMENTS_PATH = '/admin/documents/'

export const PARAM_EMPLOYEE_TIMESHEETS_PATH =  '/employee/timesheets/'
export const PARAM_PRODUCTS_PATH = '/admin/products/'
export const PARAM_EMPLOYEES_PATH = '/admin/employees/'
export const PARAM_CLIENTS_PATH = '/admin/clients/'
export const PARAM_REQUESTS_PATH = '/admin/requests/'
export const PARAM_SUPPLIERS_PATH = '/admin/suppliers/'
export const PARAM_SUPPORTS_PATH = '/admin/support/'
export const PARAM_PROJECTS_PATH = '/admin/projects/'
export const PARAM_PROJECTS_TIMESHEET_PATH = '/admin/projects/timesheet/'

export const PARAM_APPOINTMENTS_PATH = '/admin/appointments/'
export const PARAM_APPOINTMENTS_TYPE_PATH = '/admin/settings/appointment-type/'





export const FISCAL_CODE_VALIDATOR_REGEX =
  /^([A-Za-z]{6}[0-9lmnpqrstuvLMNPQRSTUV]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9lmnpqrstuvLMNPQRSTUV]{2}[A-Za-z]{1}[0-9lmnpqrstuvLMNPQRSTUV]{3}[A-Za-z]{1})$/g;



export const API_DATE_FORMAT = 'yyyy-MM-DD[T]HH:mm:ss';
export const CHIPS_DATE_FORMAT = 'DD/MM/yyyy HH:mm';

export const SETTINGS: SelectItem[] = [
  { label: "Dati aziendali", value: "/admin/settings/company", icon: "pi pi-list" },

  { label: "Tags", value: "/admin/settings/tags", icon: "pi pi-list" },
  { label: "Marchi / Brands", value: "/admin/settings/brands", icon: "pi pi-list" },
  { label: "Categorie", value: "/admin/settings/categories", icon: "pi pi-list" },
  { label: "Dati technici", value: "/admin/settings/technical-data", icon: "pi pi-list" },
{ label: "Tipo di lavorazione", value: "/admin/settings/works", icon: "pi pi-list" },
{ label: "Tipo di Appuntamento", value: "/admin/settings/appointment-type", icon: "pi pi-list" },
{ label: "Dati clienti aggiuntivi", value: "/admin/settings/personal-data", icon: "pi pi-list" },
{ label: "Locations", value: "/admin/settings/locations", icon: "pi pi-list" },
{ label: "Comuni", value: "/admin/settings/comuni", icon: "pi pi-list" },

];

export const PAGES: SelectItem[] = [
  { label: "Tipo di lavorazione", value: "/admin/settings/works", icon: "pi pi-list" },
  { label: "Tipo di Appuntamento", value: "/admin/settings/appointment-type", icon: "pi pi-list" },
  { label: "Luogo di lavorazione", value: "/admin/settings/locations", icon: "pi pi-list" },
  { label: "Dati clienti aggiuntivi", value: "/admin/settings/personal-data", icon: "pi pi-list" },

];

export const PRODUCT_TYPE_LIST: SelectItem[] = [
  { label: "Compra", value: "1" },
  { label: "Affitto", value: "2" },
  { label: "Aste", value: "3" },
  { label: "Altro", value: "4" },

];

export const PAGES_PRODUCT: SelectItem[] = [

  { label: "Tags", value: "/admin/settings/tags", icon: "pi pi-list" },
  { label: "Marchi / Brands", value: "/admin/settings/brands", icon: "pi pi-list" },
  { label: "Categorie", value: "/admin/settings/categories", icon: "pi pi-list" },
  { label: "Dati technici", value: "/admin/settings/technical-data", icon: "pi pi-list" },
  { label: "Magazzino", value: "/admin/settings/warehouses", icon: "pi pi-list" },

];

export const PAGES_COMPANY: SelectItem[] = [

  { label: "Dati aziendali", value: "/admin/settings/company", icon: "pi pi-list" },


];

export const DASHBOARD: SelectItem[] = [
  { label: "Clienti", value: "./clients", icon: "" },
  { label: "Appuntamenti", value: "./appointments" }

];




export const STATUS_PROJECTS: SelectItem[] = [
  { label: "Tutte", value: null },
  { label: "Fase di Esecuzione", value: "Esecuzione" },
  { label: "Fase di Contrattazione", value: "Contrattazione" },
  { label: "Fase di Elaborazione", value: "Elaborazione" },
  { label: "Amministrazione", value: "Amministrazione" },
  { label: "Altro", value: "Altro" }
];



export const PRIORITY_LIST: SelectItem[] = [
  { label: "Da Fare", value: "1" },
  { label: "25 %", value: "2" },
  { label: "75 %", value: "3" },
  { label: "Done", value: "4" }

];



export const STATUS_PRODUCTS: SelectItem[] = [
  { label: "Disponibile", value: "1" },
  { label: "Esaurito", value: "2" },
  { label: "Esauriro temporaneamente", value: "3" },
  { label: "In ordinazione", value: "4" },
  { label: "Altro", value: "5" }
];


export const TIMESHEETS_TYPE: SelectItem[] = [
  { label: "Lavoro", value: "1" },
  { label: "Ferie", value: "2" },
  { label: "Permesso", value: "3" },
  { label: "Straordinari", value: "4" },
  { label: "Altro", value: "5" }
];

export const LANG_IT = {
  closeText: 'Chiudi',
  prevText: '&#x3C;Prec',
  nextText: 'Succ&#x3E;',
  currentText: 'Oggi',
  monthNames: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno',
    'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
  monthNamesShort: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu',
    'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
  dayNames: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
  dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
  dayNamesMin: ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'],
  weekHeader: 'Sm',
  dateFormat: 'dd/mm/yy',
  firstDayOfWeek: 1,
  isRTL: false,
  showMonthAfterYear: false,
  yearSuffix: ''
};


  export const STATE_LIST = [
  {label: "Si", value: "1"},
  {label: "No", value: "0"}];


  export const BILLING_LIST = [
    {label: "Fattura", value: "Fattura"},
    {label: "Ricevuta", value: "Ricevuta"}];



  export const BUSINESS_STATE_LIST = [
    {label: "Business", value: "1"},
    {label: "Privato", value: "0"}];

    export const EMPLOYEE_TYPE_LIST = [
      {label: "Dipendente", value: "0"},
      {label: "Socio", value: "1"}];

export const SEX_LIST: SelectItem[] = [
  { label: "Tutte", value: null },
  { label: "Uomo", value: "Uomo" },
  { label: "Donna", value: "Donna" },
];


export const NUMBER_LIST: SelectItem[] = [
  { label: "scegli", value: null },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
];

export const SIZE_LIST: SelectItem[] = [
  { label: "20 mq", value: "20" },
  { label: "40 mq", value: "40" },
  { label: "60 mq", value: "60" },
  { label: "80 mq", value: "80" },
  { label: "100 mq", value: "100" },
  { label: "120 mq", value: "120" },
  { label: "140 mq", value: "140" },
  { label: "160 mq", value: "160" },
  { label: "180 mq", value: "180" },
  { label: "200 mq", value: "200" },
  { label: "250 mq", value: "250" },
  { label: "300 mq", value: "300" },
  { label: "350 mq", value: "350" },
  { label: "400 mq", value: "400" },
  { label: "450 mq", value: "450" },
  { label: "500 mq", value: "500" },
];

export const ROOM_LIST: SelectItem[] = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "10", value: "10" },
];

export const TYPE_LIST: SelectItem[] = [
  { label: "Compra", value: "1" },
  { label: "Affitto", value: "2" },
  { label: "Aste", value: "3" },
  { label: "Altro", value: "4" },

];



export const PRICE_LIST: SelectItem[] = [
  { label: "10.000", value: "10000" },
  { label: "20.000", value: "20000" },
  { label: "30.000", value: "30000" },
  { label: "40.000", value: "40000" },
  { label: "50.000", value: "50000" },
  { label: "60.000", value: "60000" },
  { label: "70.000", value: "70000" },
  { label: "80.000", value: "80000" },
  { label: "90.000", value: "90000" },
  { label: "100.000", value: "100000" },
  { label: "125.000", value: "125000" },
  { label: "150.000", value: "150000" },
  { label: "175.000", value: "175000" },
  { label: "200.000", value: "200000" },
  { label: "250.000", value: "250000" },
  { label: "300.000", value: "300000" },
  { label: "400.000", value: "400000" },
  { label: "500.000", value: "500000" },
  { label: "600.000", value: "600000" },
  { label: "700.000", value: "700000" },
  { label: "800.000", value: "800000" },
  { label: "900.000", value: "900000" },
  { label: "1.000.000", value: "1000000" },
];

export const ANNO_ITEMS: SelectItem[] = [
  { label: "nessuno", value: "" },
  { label: "2021", value: "2021" },
  { label: "2020", value: "2020" },
  { label: "2019", value: "2019" },
  { label: "2018", value: "2018" },
  { label: "2017", value: "2017" },
  { label: "2016", value: "2016" },
  { label: "2015", value: "2015" },
  { label: "2014", value: "2014" },
  { label: "2013", value: "2013" },
  { label: "2012", value: "2012" },
  { label: "2011", value: "2011" },
  { label: "2010", value: "2010" },
  { label: "2009", value: "2009" },
];

export const TIPO_CONTENUTO_MILTILINGUA_ITEMS: SelectItem[] = [
  { label: "...", value: "..." },
  { label: "Attività", value: "Attività" },
  { label: "Contenuti Generici", value: "Contenuti Generici" },
  { label: "Gallerie", value: "Gallerie" },
  { label: "Servizi", value: "Servizi" },
  { label: "Video", value: "Video" },
];

export const TIPO_CONTENUTO_ITEMS: SelectItem[] = [
  { label: "...", value: "..." },
  { label: "Attività", value: "Attività" },
  { label: "Contenuti Generici", value: "Contenuti Generici" },
  { label: "Documenti", value: "Documenti" },
  { label: "News", value: "News" },
  { label: "Sedi", value: "Sedi" },
  { label: "Servizi", value: "Servizi" },
  { label: "Settori", value: "Settori" },
  { label: "Sotto Attività", value: "Sotto Attività" },
  { label: "Sotto Servizi", value: "Sotto Servizi" },
  { label: "Storie", value: "Storie" },
];

export const MENU_ITEMS: any[] = [
  {
    link: "allegati",
    not: "",
    title: "Allegati",
    icon: "fa fa-paperclip",
    description: "Gestione dei Allegati",
  },
  {
    link: "attivita",
    not: "sotto",
    title: "Attività",
    icon: "fa fa-cogs",
    description: "Gestione delle Attività",
  },
  {
    link: "blog",
    not: "",
    title: "Blog",
    icon: "fa fa-book",
    description: "Blog",
  },
  {
    link: "contenuti-generici",
    not: "",
    title: "Contenuti Generici",
    icon: "fa fa-connectdevelop",
    description: "Gestione dei Contenuti Generici",
  },

  {
    link: "careers",
    not: "",
    title: "Careers",
    icon: "fa fa-file-o",
    description: "Offerte di lavoro",
  },
  {
    link: "formrequestcareer",
    not: "",
    title: " Curriculum",
    icon: "fa fa-envelope-o",
    description: "Curriculum",
  },

  {
    link: "documenti",
    not: "",
    title: "Documenti",
    icon: "fa fa-file-o",
    description: "Gestione dei Documenti",
  },
  {
    link: "multilingue",
    not: "",
    title: "Multilingue",
    icon: "fa fa-language",
    description: "Gestione delle Multilingue",
  },
  {
    link: "news",
    not: "",
    title: "News",
    icon: "fa fa-newspaper-o",
    description: "Gestione delle News",
  },
  {
    link: "sedi",
    not: "",
    title: "Sedi",
    icon: "fa fa-home",
    description: "Gestione delle Sedi",
  },
  {
    link: "servizi",
    not: "sotto",
    title: "Servizi",
    icon: "fa fa-server",
    description: "Gestione dei Servizi",
  },
  {
    link: "settori",
    not: "",
    title: "Settori",
    icon: "fa fa-wpforms",
    description: "Gestione dei Settori",
  },
  {
    link: "sottoattivita",
    not: "",
    title: "Sotto Attività",
    icon: "fa fa-cog",
    description: "Gestione delle Sotto Attività",
  },
  {
    link: "sottoservizi",
    not: "",
    title: "Sotto Servizi",
    icon: "fa fa-th-list",
    description: "Gestione dei Sotto Servizi",
  },
  {
    link: "storie",
    not: "",
    title: "Storia",
    icon: "fa fa-history",
    description: "Gestione della Storia",
  },
  {
    link: "video",
    not: "",
    title: "Video",
    icon: "fa fa-youtube",
    description: "Gestione dei Video",
  },
  {
    link: "gallerie",
    not: "",
    title: "Gallerie di Foto",
    icon: "fa fa-picture-o",
    description: "Gestione delle Gallerie di Foto",
  },
  {
    link: "formrequest",
    not: "",
    title: "Richieste",
    icon: "fa fa-envelope-o",
    description: "Richieste",
  },
];

export const APP_VERSION = "2.0";
export const EDITOR_CONFIGURATION =
  'selector:"textarea", plugins: "table",  menubar: "false", height: 400, toolbar: "table | undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent"';
