import { Component } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractListController } from 'src/app/admin/common/abstract-list.component';
import { Appointments } from 'src/app/models/appointments';
import { AppointmentsService } from 'src/app/services/appointments.service';
import { PARAM_APPOINTMENTS_PATH, PARAM_PROJECTS_PATH } from '../../constants/constants';
import { ClientsService } from 'src/app/services/clients.service';
import { Clients } from 'src/app/models/clients';
import { Projects } from 'src/app/models/projects';
import { ProjectsService } from 'src/app/services/projects.service';

@Component({
  selector: 'app-manage-projects',
  templateUrl: './manage-projects.component.html'
})
export class ManageProjectsComponent extends AbstractListController<Projects, any> {
  title = 'Progetti';
  titleFilter: string = '';
  descriptionFilter: string = '';
  cols: any[];
  columnOptions: any[];
  selectedColumns: any[];
  productDialog: boolean = false;
  appointment: Appointments;
  client: Clients;
  selectedWorks: any[];

  constructor(
    protected projectsService: ProjectsService,
    private clientService: ClientsService,
    protected confirmationService: ConfirmationService,
    router: Router,
    route: ActivatedRoute
  ) {
    super(projectsService, confirmationService, router, route, PARAM_PROJECTS_PATH);

    this.cols = [
      { field: "name", header: "Nome" },
      { field: "surname", header: "Cognome" },

      { field: "date", header: "Data di nascità" },
      { field: "phone", header: "Cellulare" },
      { field: "city", header: "Indirizzo" }
    ];

    this.columnOptions = [];
    this.selectedColumns = [];
    for (let i = 0; i < this.cols.length; i++) {
      this.columnOptions.push({ label: this.cols[i].header, value: this.cols[i] });
    }
  }
  

  getFilterParams(): any {
    return {
      title: this.titleFilter,
      description: this.descriptionFilter
    };
  }

  applyFilterParams(params: any): void {
    this.titleFilter = params['title'] || '';
    this.descriptionFilter = params['description'] || '';
  }

  


  filterItem(item: Projects, filters: any): boolean {
    const nameMatch = !filters.title || item.title.toLowerCase().includes(filters.title.toLowerCase());
    const descriptionMatch = !filters.description || item.description.toLowerCase().includes(filters.description.toLowerCase());
    return nameMatch && descriptionMatch;
  }

  clearFilters(): void {
    this.titleFilter = '';
    this.descriptionFilter = '';
  }
}

