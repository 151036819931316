import { Component } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractListController } from 'src/app/admin/common/abstract-list.component';
import { Appointments } from 'src/app/models/appointments';
import { AppointmentsService } from 'src/app/services/appointments.service';
import { PARAM_APPOINTMENTS_PATH } from '../../constants/constants';
import { ClientsService } from 'src/app/services/clients.service';
import { Clients } from 'src/app/models/clients';

@Component({
  selector: 'app-manage-appointments',
  templateUrl: './manage-appointments.component.html'
})
export class ManageAppointmentsComponent extends AbstractListController<Appointments, any> {
  title = 'Appuntamenti';
  dateFromFilter: string = '';
  dateToFilter: string = '';
  cols: any[];
  columnOptions: any[];
  selectedColumns: any[];
  productDialog: boolean = false;
  appointment: Appointments;
  client: Clients;
  selectedWorks: any[];

  constructor(
    protected appointmentsService: AppointmentsService,
    private clientService: ClientsService,
    protected confirmationService: ConfirmationService,
    router: Router,
    route: ActivatedRoute
  ) {
    super(appointmentsService, confirmationService, router, route, PARAM_APPOINTMENTS_PATH);

    this.cols = [
      { field: "name", header: "Nome" },
      { field: "surname", header: "Cognome" },

      { field: "date", header: "Data di nascità" },
      { field: "phone", header: "Cellulare" },
      { field: "city", header: "Indirizzo" }
    ];

    this.columnOptions = [];
    this.selectedColumns = [];
    for (let i = 0; i < this.cols.length; i++) {
      this.columnOptions.push({ label: this.cols[i].header, value: this.cols[i] });
    }
  }
  

  getFilterParams(): any {
    return {
      dateFrom: this.dateFromFilter,
      dateTo: this.dateToFilter
    };
  }

  applyFilterParams(params: any): void {
    this.dateFromFilter = params['date_from'] || '';
    this.dateToFilter = params['date_to'] || '';
  }

  
  edit(appointment: Appointments) {
    this.appointment = { ...appointment };

      this.clientService.getId(+this.appointment.category_id).subscribe((res) => {
        this.client = res;
      });

      this.selectedWorks = this.appointment.works_id.split(',');
          this.productDialog = true;
  }


  filterItem(item: Appointments, filters: any): boolean {
    const nameMatch = !filters.date || item.date.toLowerCase().includes(filters.date.toLowerCase());
    const descriptionMatch = !filters.description || item.description.toLowerCase().includes(filters.description.toLowerCase());
    return nameMatch && descriptionMatch;
  }

  clearFilters(): void {
    this.dateFromFilter = '';
    this.dateToFilter = '';
  }
}

