import { Component } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { CategoryService } from 'src/app/services/categories.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractListController } from 'src/app/admin/common/abstract-list.component';
import { Tags } from 'src/app/models/tags';
import { TagsService } from 'src/app/services/tags.service';
import { PARAM_TAGS_PATH, PARAM_WORKS_PATH } from 'src/app/admin/constants/constants';
import { TypeService } from 'src/app/services/type.service';
import { Type } from 'src/app/models/type';
import { WorksService } from 'src/app/services/works.service';
import { Works } from 'src/app/models/works';

@Component({
  selector: "app-manage-works",
  templateUrl: "./manage-works.component.html",
})
export class ManageWorksComponent extends AbstractListController<Works, any> {
  title = 'Tags';
  nameFilter: string = '';
  descriptionFilter: string = '';
  cols: any[];
  columnOptions: any[];
  selectedColumns: any[];
  
  constructor(
    protected workService: WorksService,
    protected confirmationService: ConfirmationService,
    router: Router,
    route: ActivatedRoute
  ) {
    super(workService, confirmationService, router, route, PARAM_WORKS_PATH);

    this.cols = [
      { field: 'is_active', header: 'Attivo', index: 1 },
      { field: 'name', header: 'Nome', index: 1 },
      { field: 'description', header: 'Descrizione', index: 2 }
    ];

    this.columnOptions = [];
    this.selectedColumns = [];
    for (let i = 0; i < this.cols.length; i++) {
      this.columnOptions.push({ label: this.cols[i].header, value: this.cols[i] });
    }
  }
  

  
  getFilterParams(): any {
    return {
      name: this.nameFilter,
      description: this.descriptionFilter
    };
  }

  applyFilterParams(params: any): void {
    this.nameFilter = params['name'] || '';
    this.descriptionFilter = params['description'] || '';
  }

  
  filterItem(item: Works, filters: any): boolean {
    const nameMatch = !filters.name || item.name.toLowerCase().includes(filters.name.toLowerCase());
    const descriptionMatch = !filters.description || item.description.toLowerCase().includes(filters.description.toLowerCase());
    return nameMatch && descriptionMatch;
  }

  clearFilters(): void {
    this.nameFilter = '';
    this.descriptionFilter = '';
  }
}
