import { Component } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractListController } from 'src/app/admin/common/abstract-list.component';
import { Appointments } from 'src/app/models/appointments';
import { AppointmentsService } from 'src/app/services/appointments.service';
import { PARAM_APPOINTMENTS_PATH } from '../../constants/constants';
import { Employees } from 'src/app/models/employees';
import { EmployeesService } from 'src/app/services/employees.service';


@Component({
  selector: 'app-manage-employees',
  templateUrl: './manage-employees.component.html'
})
export class ManageEmployeesComponent extends AbstractListController<Employees, any> {
  title = 'Appuntamenti';
  nameFilter: string = '';
  surnameFilter: string = '';
  cols: any[];
  columnOptions: any[];
  selectedColumns: any[];
  productDialog: boolean = false;
  appointment: Appointments;
  employee: Employees;
  selectedWorks: any[];

  constructor(
    protected appointmentsService: AppointmentsService,
    private employeesService: EmployeesService,
    protected confirmationService: ConfirmationService,
    router: Router,
    route: ActivatedRoute
  ) {
    super(employeesService, confirmationService, router, route, PARAM_APPOINTMENTS_PATH);

    this.cols = [
      { field: "name", header: "Nome" },
      { field: "surname", header: "Cognome" },

      { field: "date", header: "Data di nascità" },
      { field: "phone", header: "Cellulare" },
      { field: "city", header: "Indirizzo" }
    ];

    this.columnOptions = [];
    this.selectedColumns = [];
    for (let i = 0; i < this.cols.length; i++) {
      this.columnOptions.push({ label: this.cols[i].header, value: this.cols[i] });
    }
  }
  

  getFilterParams(): any {
    return {
      name: this.nameFilter,
      surname: this.surnameFilter
    };
  }

  applyFilterParams(params: any): void {
    this.nameFilter = params['name'] || '';
    this.surnameFilter = params['surname'] || '';
  }

  
  edit(employee: Employees) {
    this.employee = { ...employee };



      this.selectedWorks = this.appointment.works_id.split(',');
          this.productDialog = true;
  }


  filterItem(item: Employees, filters: any): boolean {
    const nameMatch = !filters.name || item.date.toLowerCase().includes(filters.name.toLowerCase());
    const surnameMatch = !filters.surname || item.description.toLowerCase().includes(filters.surname.toLowerCase());
    return nameMatch && surnameMatch;
  }

  clearFilters(): void {
    this.nameFilter = '';
    this.surnameFilter = '';
  }
}