import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService, ConfirmationService, SelectItem } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { AbstractFormComponent } from 'src/app/admin/common/abstract-edit.component';
import { TagsService } from 'src/app/services/tags.service';
import { PARAM_APPOINTMENTS_PATH, PARAM_TAGS_PATH, STATE_LIST } from 'src/app/admin/constants/constants';
import { Appointments } from 'src/app/models/appointments';
import { AppointmentsService } from 'src/app/services/appointments.service';
import { Clients } from 'src/app/models/clients';
import { AppointmentTypeService } from 'src/app/services/appointment_type.service';
import { EmployeesService } from 'src/app/services/employees.service';
import { Employees } from 'src/app/models/employees';
import { WorksService } from 'src/app/services/works.service';
import { LocationsService } from 'src/app/services/locations.service';
import { Category } from 'src/app/models/category';
import { Works } from 'src/app/models/works';
import { Locations } from 'src/app/models/locations';
import { ClientsService } from 'src/app/services/clients.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: "app-appointments-form",
  templateUrl: "./appointments-form.component.html",
})


export class AppointmentsFormComponent  extends AbstractFormComponent<Appointments> implements OnInit {
  pageTitle: string;
  form: FormGroup;
  idField = 'id';
  formFields = ['id', 'title', 'description', 'is_featured', 'category_id', 'works_id', 'location_id', 'employee_id', 'is_active', 'date' ];
  item$: Observable<Appointments> = of(); // Initialize with an empty Observable
  stateOptions = STATE_LIST;
  service = this.appointmentsService; // Implement the abstract service property
  client: Clients;
  clients: Clients[] = [];
  selectedClients: Clients;
  selectedWorks: SelectItem[] = [];
  works_id: any;
  appointment_type: any = [];
  employees: Employees[];
  employee: Employees;
  categories: Category[] = [];
  category: Category;
  works: Works[] = [];
  work: Works;
  locations: Locations[] = [];
  location: Locations;

  constructor(
    protected fb: FormBuilder,
    private appointmentsService: AppointmentsService,
    messageService: MessageService,
    confirmationService: ConfirmationService,
    private appointmentTypeService: AppointmentTypeService,
    private employeesService: EmployeesService,
    private clientsService: ClientsService,
    private locationsService: LocationsService,
    private worksService: WorksService,
    private spinner: NgxSpinnerService,
    router: Router,
    route: ActivatedRoute,
  ) {
    super(fb, messageService, confirmationService, router, route, PARAM_APPOINTMENTS_PATH);
  }

  ngOnInit() {
    
    this.initializeForm();
    this.getselectedWorks;
    
    const paramsAppointmentType = [];
    this.appointmentTypeService
      .list(paramsAppointmentType)
      .subscribe((pData) => {
        this.appointment_type = pData;
        (error) => (this.error = error);
      });

    const paramsEmployees = [];
    this.employeesService.list(paramsEmployees).subscribe((pData) => {
      this.employees = pData;
      (error) => (this.error = error);
    });

    const paramsWorks = [];
    this.worksService.list(paramsWorks).subscribe((pData) => {
      this.works = pData;
      (error) => (this.error = error);
    });

    const paramsLocations = [];
    this.locationsService.list(paramsLocations).subscribe((pData) => {
      this.locations = pData;
      (error) => (this.error = error);
    });

    const paramsClients = [];
    this.clientsService.list(paramsClients).subscribe((pData) => {
      this.clients = pData;
      (error) => (this.error = error);
    });

    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.pageTitle = 'Modifica Appuntamento';
      this.loadItem(+id);
    } else {
      this.pageTitle = 'Aggiungi Appuntamento';
    }
  }

  getselectedWorks() {
    this.selectedWorks = this.works_id.split(",");
  }

  getCategoryItem(category_id: any, id: string) {
    return this.clients.find((item) => item.id === category_id);
  }

  fillClients(): void {
    this.client.id = this.selectedClients.id;
    this.client.name = this.selectedClients.name;
  }

  getWorksItem(works_id: any, id: string) {
    return this.works.find((item) => item.id === works_id);
  }

  initializeForm() {
    this.form = this.fb.group({
      id: [""],
      title: ["", Validators.required],
      description: [""],
      is_featured: ["0"],
      category_id: ["", Validators.required],
      works_id: [""],
      location_id: [""],
      employee_id: [""],
      is_active: ["0"],
      image: [""],
      date: ["", Validators.required],
    });
  }

  loadItem(id: number) {
    this.service.getId(id).subscribe(
      res => {
        this.form.patchValue({
          title: res.title,
          description: res.description.split(","),
          category_id: res.category_id,
          works_id: res.works_id.split(","),
          employee_id: res.employee_id,
          location_id: res.location_id,
          is_featured: res.is_featured,
          is_active: res.is_active,
          date: res.date,
          id: res.id,
        });
      },
      error => this.error = error
    );
  }
}
