import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService, ConfirmationService } from 'primeng/api';
import { Observable } from 'rxjs';

export abstract class AbstractFormComponent<T> implements OnInit {
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

  abstract pageTitle: string;
  abstract form: FormGroup;
  abstract service: any; // Service to perform CRUD operations
  abstract item$: Observable<T>;
  abstract idField: string;
  abstract formFields: string[];
  abstract initializeForm(): void;
  abstract loadItem(id: number): void;

  error: string;
  uploadError: string;
  imagePath: any;
  currentUser: any;

  constructor(
    protected fb: FormBuilder,
    protected messageService: MessageService,
    protected confirmationService: ConfirmationService,
    protected router: Router,
    protected route: ActivatedRoute,
    public path: string
  ) {

  }

  ngOnInit() {
    this.initializeForm();
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.loadItem(+id);
    }
  }

  navigateAfterSave(): void {
    this.router.navigate(['/' + this.path]);
  }


  onSubmit() {
    const formData = new FormData();
    this.formFields.forEach(field => {
      formData.append(field, this.form.get(field).value);
    });

    const id = this.form.get(this.idField).value;

    if (id) {
      this.service.update(formData, id).subscribe(
        res => {
          if (res.status === 'error') {
            this.uploadError = res.message;
          } else {
            this.messageService.add({ key: 'myKey1', severity: 'success', summary: 'Success', detail: 'Saved successfully' });
          }
        },
        error => this.error = error
      );
    } else {
      this.service.create(formData).subscribe(
        res => {
          if (res.status === 'error') {
            this.uploadError = res.message;
          } else {
            this.navigateAfterSave();
          }
        },
        error => this.error = error
      );
    }
  }
}
