import { Component } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractListController } from 'src/app/admin/common/abstract-list.component';
import { Technical_data } from 'src/app/models/technical_data';
import { Appointment_type } from 'src/app/models/appointment_type';
import { AppointmentTypeService } from 'src/app/services/appointment_type.service';
import { PARAM_APPOINTMENTS_PATH, PARAM_APPOINTMENTS_TYPE_PATH } from 'src/app/admin/constants/constants';

@Component({
  selector: 'app-manage-appointment-type',
  templateUrl: './manage-appointment-type.component.html'
})
export class ManageAppointmentTypeComponent extends AbstractListController<Appointment_type, any> {
  title = 'Tipo di Appuntamento';
  nameFilter: string = '';
  descriptionFilter: string = '';
  cols: any[];
  columnOptions: any[];
  selectedColumns: any[];
  
  constructor(
    protected appointmentTypeService: AppointmentTypeService,
    protected confirmationService: ConfirmationService,
    router: Router,
    route: ActivatedRoute
  ) {
    super(appointmentTypeService, confirmationService, router, route, PARAM_APPOINTMENTS_TYPE_PATH);

    this.cols = [
      { field: 'is_active', header: 'Attivo', index: 1 },
      { field: 'name', header: 'Nome', index: 1 },
      { field: 'description', header: 'Descrizione', index: 2 }
    ];

    this.columnOptions = [];
    this.selectedColumns = [];
    for (let i = 0; i < this.cols.length; i++) {
      this.columnOptions.push({ label: this.cols[i].header, value: this.cols[i] });
    }
  }
  

  
  getFilterParams(): any {
    return {
      name: this.nameFilter,
      description: this.descriptionFilter
    };
  }

  applyFilterParams(params: any): void {
    this.nameFilter = params['name'] || '';
    this.descriptionFilter = params['description'] || '';
  }

  
  filterItem(item: Appointment_type, filters: any): boolean {
    const nameMatch = !filters.name || item.name.toLowerCase().includes(filters.name.toLowerCase());
    const descriptionMatch = !filters.description || item.description.toLowerCase().includes(filters.description.toLowerCase());
    return nameMatch && descriptionMatch;
  }

  clearFilters(): void {
    this.nameFilter = '';
    this.descriptionFilter = '';
  }
}

