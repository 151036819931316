import { Component } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractListController } from 'src/app/admin/common/abstract-list.component';
import { PARAM_CATEGORIES_PATH, PARAM_CLIENTS_PATH, PARAM_REQUESTS_PATH } from 'src/app/admin/constants/constants';
import { Clients } from 'src/app/models/clients';
import { ClientsService } from 'src/app/services/clients.service';
import { Requests } from 'src/app/models/requests';
import { RequestsService } from 'src/app/services/requets.service';
import { Products } from 'src/app/models/products';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-manage-requests',
  templateUrl: './manage-requests.component.html'
})
export class ManageRequestsComponent extends AbstractListController<Requests, any> {
  title = 'Richieste';
  nameFilter: string = '';
  surnameFilter: string = '';
  cols: any[];
  columnOptions: any[];
  selectedColumns: any[];
  request: Requests;
  productDialog: boolean = false;
  requestProduct: Products;

  constructor(
    protected requestsService: RequestsService,
    protected confirmationService: ConfirmationService,
    private productsService: ProductsService,
    private messageService: MessageService,
    router: Router,
    route: ActivatedRoute
  ) {
    super(requestsService, confirmationService, router, route, PARAM_REQUESTS_PATH);

    this.cols = [
      { field: "name", header: "Nome" },
      { field: "address", header: "Indirizzo" },

      { field: "phone", header: "Telefono" },
      { field: "email", header: "Email" },
      { field: "created_at", header: "Data" },

      
    ];

    this.columnOptions = [];
    this.selectedColumns = [];
    for (let i = 0; i < this.cols.length; i++) {
      this.columnOptions.push({ label: this.cols[i].header, value: this.cols[i] });
    }
  }
  

  edit(request: Requests) {
    this.request = { ...request };

    if(this.request.ref_id !== '0') {
        this.productsService.getId(+this.request.ref_id).subscribe((res) => {
        this.requestProduct = res
        });
    }
    this.productDialog = true;
  }
  
  getFilterParams(): any {
    return {
      name: this.nameFilter,
    };
  }

  applyFilterParams(params: any): void {
    this.nameFilter = params['name'] || '';
  }

  
  close(request: Requests) {
    const formData = new FormData();
   
    formData.set("status", "0");
    this.requestsService.update(formData, request.id).subscribe(
      (res) => {
        this.load();
        this.messageService.add({
          key: "myKey1",
          severity: "success",
          summary: "Attenzione",
          detail: "Aggiornato con success",
        });
      },
      (error) => (this.error = error)
    );
  }

  open(request: Requests) {
    const formData = new FormData();
   
    formData.set("status", "1");

    this.requestsService.update(formData, request.id).subscribe(
      (res) => {
        this.load();
        this.messageService.add({
          key: "myKey1",
          severity: "success",
          summary: "Attenzione",
          detail: "Aggiornato con success",
        });
      },
      (error) => (this.error = error)
    );
  }


  filterItem(item: Requests, filters: any): boolean {
    const nameMatch = !filters.name || item.name.toLowerCase().includes(filters.name.toLowerCase());
    return nameMatch;
  }

  clearFilters(): void {
    this.nameFilter = '';
  }
}

