import { Component } from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { Router, ActivatedRoute } from "@angular/router";
import { AbstractListController } from "src/app/admin/common/abstract-list.component";
import { Appointments } from "src/app/models/appointments";
import { AppointmentsService } from "src/app/services/appointments.service";
import {
  PARAM_APPOINTMENTS_PATH,
  PARAM_BILLINGS_PATH,
} from "../../constants/constants";
import { ClientsService } from "src/app/services/clients.service";
import { Clients } from "src/app/models/clients";
import { Billings } from "src/app/models/billings";
import { BillingsService } from "src/app/services/billings.service";

@Component({
  selector: "app-manage-billings",
  templateUrl: "./manage-billings.component.html",
})
export class ManageBillingsComponent extends AbstractListController<
  Billings,
  any
> {
  title = "Fatture";
  dateFromFilter: string = "";
  dateToFilter: string = "";
  cols: any[];
  columnOptions: any[];
  selectedColumns: any[];
  productDialog: boolean = false;
  appointment: Appointments;
  billing: Billings;
  selectedSkills: any[];

  constructor(
    protected billingsService: BillingsService,
    private messageService: MessageService,
    protected confirmationService: ConfirmationService,
    router: Router,
    route: ActivatedRoute
  ) {
    super(
      billingsService,
      confirmationService,
      router,
      route,
      PARAM_BILLINGS_PATH
    );

    this.cols = [
      { field: "name", header: "Nome" },
      { field: "surname", header: "Cognome" },
      { field: "date", header: "Data di nascità" },
      { field: "phone", header: "Cellulare" },
      { field: "city", header: "Indirizzo" },
    ];

    this.columnOptions = [];
    this.selectedColumns = [];
    for (let i = 0; i < this.cols.length; i++) {
      this.columnOptions.push({
        label: this.cols[i].header,
        value: this.cols[i],
      });
    }
  }

  getFilterParams(): any {
    return {
      dateFrom: this.dateFromFilter,
      dateTo: this.dateToFilter,
    };
  }

  applyFilterParams(params: any): void {
    this.dateFromFilter = params["date_from"] || "";
    this.dateToFilter = params["date_to"] || "";
  }

  filterItem(item: Billings, filters: any): boolean {
    const nameMatch =
      !filters.date ||
      item.date.toLowerCase().includes(filters.date.toLowerCase());
    const descriptionMatch =
      !filters.description ||
      item.description
        .toLowerCase()
        .includes(filters.description.toLowerCase());
    return nameMatch && descriptionMatch;
  }

  clearFilters(): void {
    this.dateFromFilter = "";
    this.dateToFilter = "";
  }

  closeBilling(billing: Billings) {
    const formData = new FormData();
   
    formData.set("is_paid", "0");
    this.billingsService.update(formData, billing.id).subscribe(
      (res) => {
        this.load();
        this.messageService.add({
          key: "myKey1",
          severity: "success",
          summary: "Attenzione",
          detail: "Aggiornato con success",
        });
      },
      (error) => (this.error = error)
    );
  }

  openBilling(billing: Billings) {
    const formData = new FormData();
   
    formData.set("is_paid", "1");

    this.billingsService.update(formData, billing.id).subscribe(
      (res) => {
        this.load();
        this.messageService.add({
          key: "myKey1",
          severity: "success",
          summary: "Attenzione",
          detail: "Aggiornato con success",
        });
      },
      (error) => (this.error = error)
    );
  }

  edit(billing: Billings) {
    this.billing = { ...billing };
    this.selectedSkills = JSON.parse("" + this.billing.skills + "");
    this.productDialog = true;
  }
}
