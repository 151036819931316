import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Blog } from '../../../models/blog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DASHBOARD, PAGES, TYPE_LIST } from '../../constants/constants';
import { Category } from 'src/app/models/category';
import { ClientsService } from 'src/app/services/clients.service';
import { Clients } from 'src/app/models/clients';
import { Appointments } from 'src/app/models/appointments';
import { AppointmentsService } from 'src/app/services/appointments.service';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi, EventInput } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import * as $ from 'jquery';
import { formatDate } from '@angular/common';
import { Locations } from 'src/app/models/locations';
import { Employees } from 'src/app/models/employees';
import { WorksService } from 'src/app/services/works.service';
import { Works } from 'src/app/models/works';
import * as moment from 'moment';
import 'moment/locale/it'  // without this line it didn't work
import { Supports } from 'src/app/models/supports';

import { WarehouseCheckins } from 'src/app/models/warehouse_checkins';
import { AppointmentTypeService } from 'src/app/services/appointment_type.service';
import { Appointment_type } from 'src/app/models/appointment_type';
import { FullCalendarComponent } from '@fullcalendar/angular';


@Component({
  selector: 'app-appointments-calendar',
  templateUrl: './appointments-calendar.component.html'
})
export class AppointmentsCalendarComponent implements OnInit {

  events: any;
  appointments: any = [];
  appointment: Appointments;
  appointmentsToday:  any = [];
  locations: any = [];
  location: Locations;

  employees: any = [];
  employee: Employees;
  warehouseCheckins: any = [];
  warehouseCheckin: WarehouseCheckins;
  works: any = [];
  work: Works;
  clientsCount: any;
  supports: any = [];
  support: Supports;
  productsCount: any;
  error: string;
  blogForm: UntypedFormGroup;
  typeList: any;
  cities: Blog[];
  selectedCity: Blog;
  uploadError: string;
  selectedDate: Date;
  date: Date;
  selectedWorks: any[];
  categories: any = [];
  category: Category;
  productDialog: boolean = false;
  appointmentsDialog: boolean = false;
  clients: any = [];
  client: Clients;
  comuni: any = [];
  displayEvent: any;
  data1: EventInput[] = []; // Explicitly type data1 as EventInput[]
  data2 = [];
  count = 0;
  pageSize = 3;
  page = 1;
  curdate: string;

  trackByFn(index, item) {
    return item.id;
  }
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  myMonth = formatDate(new Date(), 'dd/MM/yyyy', 'en');
  nameFilter: string;
  descriptionFilter: string;
  dateFromFilter: string;
  dateToFilter: string;
  currentDate: moment.Moment = moment();
  currentTime: string = moment().format(' MMMM YYYY');
  appointmenttype: any = [];
  appointment_typ: Appointment_type;

  @ViewChild('mychart') mychart;
  currentEvents = <EventApi[]>([]);
  eventId: string

  calendarVisible = true;
  calendarOptions = <CalendarOptions>({
    plugins: [
      interactionPlugin,
      dayGridPlugin,
      timeGridPlugin,
      listPlugin,
    ],
    headerToolbar: {
      left: 'prev,next',
      center: 'title',
      right: 'today'
    },

    initialView: 'dayGridMonth',
    events: this.data1 as any, // alternatively, use the `events` setting to fetch from a feed
    weekends: true,
    
    timeZone: 'America/New_York',
    eventTimeFormat: {
      hour: "2-digit",
      minute: "2-digit",
      meridiem: false,
      hour12: false
    },

    customButtons: {
      next: {
        text: 'custom!',
        click: this.customNext.bind(this)
      },
      prev: {
        text: 'custom!',
        click: this.customPrev.bind(this)
      }
    },

    editable: true,
    selectable: true,
    selectMirror: true,
    locale: 'it',
    dayMaxEvents: true,
    eventClick: this.handleEventClick.bind(this),
    eventsSet: this.handleEvents.bind(this),
    datesSet: this.handleDatesSet.bind(this),  // Add this line;
    /* you can update a remote database when these fire:
    eventAdd:
    eventChange:
    eventRemove:
    */
  });

  constructor(
    private appointmentsService: AppointmentsService,
    private clientService: ClientsService,
    private changeDetector: ChangeDetectorRef,
    private worksService: WorksService,
    private appointmentTypeService: AppointmentTypeService,
  ) {
    this.typeList = TYPE_LIST;

  }

  customFunction(){
    
    alert('Custom Function Called')
  }

  ngOnInit(): void {


    const paramsAppointmentType = []
    this.appointmentTypeService.list(paramsAppointmentType).subscribe((pData) => {
      this.appointmenttype = pData;
      (error) => (this.error = error);
    });

    const paramsWorks = []
    this.worksService.list(paramsWorks).subscribe((pData) => {
      this.works = pData;
      (error) => (this.error = error);
    });
    

  }
  

  handleDatesSet(dateInfo) {
    const start = formatDate(dateInfo.start, "yyyy-MM-dd 00:00", "en");
    const end = formatDate(dateInfo.end, "yyyy-MM-dd 00:00", "en");
  
    const params = {
      page: 0,
      size: 200,
      dateFrom: start,
      dateTo: end,
    };
  
    this.appointmentsService.list(params).subscribe((pData) => {
      this.appointments = pData;
      this.count = this.appointmentsService.size;
  
      this.data1 = []; // Clear current events
      
      this.appointments.forEach((item) => {
        var obj;
        obj = {
          title: item.client.name + ' ' + item.client.surname,
          date: formatDate(item.date, "yyyy-MM-dd HH:mm", "en"),
          id: item.id
        };
        this.data1.push(obj);
      });
  
      this.calendarOptions.events = this.data1; // Update the events in the calendar
    });
  }

  customNext(event) {
    let calendarApi = this.calendarComponent.getApi();
    calendarApi.next(); // Navigate to the next month
  }
  
  customPrev() {
    let calendarApi = this.calendarComponent.getApi();
    calendarApi.prev(); // Navigate to the previous month
  }






  handleEventClick(clickInfo: EventClickArg) {
      this.productDialog = true;
      this.eventId = clickInfo.event.id;

      this.appointmentsService.getId(+clickInfo.event.id).subscribe((res) => {
        this.appointment = res;

        this.clientService.getId(+this.appointment.category_id).subscribe((res) => {
          this.client = res;
        });

        this.selectedWorks = this.appointment.works_id.split(',');

  });

  }

  

  handleEvents(events: EventApi[]) {
    this.currentEvents.concat(events);
    this.changeDetector.detectChanges(); // workaround for pressionChangedAfterItHasBeenCheckedError
  }

 

  editProduct(event: any) {
    this.productDialog = true;

    
    this.appointmentsService.getId(+event.id).subscribe((res) => {
          this.appointment = res;

          this.clientService.getId(+this.appointment.category_id).subscribe((res) => {
            this.client = res;
          });

          this.selectedWorks = this.appointment.works_id.split(',');

    });

   
  }



  showDialog() {
    this.productDialog = true;
  }

  clickButton(model: any) {
    this.displayEvent = model;

  }
  eventClick(model: any) {
    model = {
      event: {
        id: model.event.id,
        start: model.event.start,
        title: model.event.title,
        works_id: model.event.works_id.split(','),
        location_id: model.event.location_id,
        employee_id: model.event.employee_id,
        allDay: model.event.allDay,
        description: model.event.description,
        category_id: model.event.category_id,
        client: model.event.client

        // other params
      },
      duration: {}
    }
    this.displayEvent = model;
    this.productDialog = true;

  }

  eventRender(event) {
    const html = `<div>
      <div class="event-time"><i class="pi pi-clock"></i> ${event.event.time}</div>
      <div class="event-client-name"><strong>${event.event.client.name}</strong><br>${event.event.client.surname}</div>

    </div>`;
    event.element.html(html)
  }

  dayClick(event) {
    console.log('dayClick', event);
  }




}
