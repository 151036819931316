import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService, ConfirmationService } from 'primeng/api';
import { CategoryService } from '../../../../services/categories.service';
import { Category } from '../../../../models/category';
import { Observable, of } from 'rxjs';
import { AbstractFormComponent } from 'src/app/admin/common/abstract-edit.component';
import { PARAM_CATEGORIES_PATH } from 'src/app/admin/constants/constants';

@Component({
  selector: 'app-category-form',
  templateUrl: './category-form.component.html'
})
export class CategoryFormComponent extends AbstractFormComponent<Category> implements OnInit {
  pageTitle: string;
  form: FormGroup;
  idField = 'id';
  formFields = ['id', 'name', 'description', 'seo_url', 'is_active'];
  item$: Observable<Category> = of(); // Initialize with an empty Observable

  service = this.categoryService; // Implement the abstract service property

  constructor(
    protected fb: FormBuilder,
    private categoryService: CategoryService,
    messageService: MessageService,
    confirmationService: ConfirmationService,
    router: Router,
    route: ActivatedRoute,
  ) {
    super(fb, messageService, confirmationService, router, route, PARAM_CATEGORIES_PATH);
  }

  ngOnInit() {
    this.initializeForm();
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.pageTitle = 'Modifica Categoria';
      this.loadItem(+id);
    } else {
      this.pageTitle = 'Aggiungi Categoria';
    }
  }

  initializeForm() {
    this.form = this.fb.group({
      id: [''],
      name: ['', Validators.required],
      description: [''],
      seo_url: [''],
      is_active: ["0"]
    });
  }

  loadItem(id: number) {
    this.service.getId(id).subscribe(
      res => {
        this.form.patchValue({
          name: res.name,
          description: res.description,
          seo_url: res.seo_url,
          is_active: res.is_active,
          id: res.id
        });
      },
      error => this.error = error
    );
  }
}
