import { Component, ElementRef, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
  UntypedFormArray,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ViewChild } from "@angular/core";
import { Chart } from "chart.js";

import { Blog } from "../../../models/blog";
import { Category } from "../../../models/category";
import { SelectItem } from "primeng/api";
import * as moment from "moment";
import {
  TYPE_LIST,
  STATUS_PRODUCTS,
  STATE_LIST,
} from "../../constants/constants";
import { Clients } from "src/app/models/clients";
import { DatePipe, Location } from "@angular/common";
import { WorksService } from "src/app/services/works.service";
import { Works } from "src/app/models/works";
import { EmployeesService } from "src/app/services/employees.service";
import { Employees } from "src/app/models/employees";
import { Locations } from "src/app/models/locations";
import { Appointments } from "src/app/models/appointments";
import { Projects } from "src/app/models/projects";
import { Brand } from "src/app/models/brand";
import { BrandService } from "src/app/services/brands.service";
import { Technical_data } from "src/app/models/technical_data";
import { TechnicalDataService } from "src/app/services/technical_data.service";
import { ProjectsService } from "src/app/services/projects.service";
import { TimesheetsService } from "src/app/services/timesheets.service";
import { Subscription } from "rxjs";

export interface fPairs {
  qty: number;
  price: number;
}

@Component({
  selector: "app-projects-productivity",
  templateUrl: "./projects-productivity.component.html",
})
export class ProjectsProductivityComponent implements OnInit {
  @ViewChild("myInput", { static: false }) myInputVariable: ElementRef;

  @ViewChild("mychart") mychart;
  @ViewChild("canvas") canvasEl: ElementRef;

  pageTitle: string;
  error: string;
  uploadError: string;
  imagePath: any;
  blogs: Blog;
  blog: Blog;
  id: number;
  price: any;
  appointments: Appointments;
  appointment: any;

  categories: any = [];
  category: Category;

  works: any = [];
  work: Works;

  checked: boolean = true;
  selectedValue: string;
  products: any = [];
  product: Projects;
  blogForm: UntypedFormGroup;
  typeList: any[];
  status: any[];
  stateOptions: any[];

  clients: any = [];
  client: Clients;
  brands: any = [];

  employee: Employees;
  employees: any = [];
  arrString: string;

  timesheetsEmployee: any = [];
  brand: Brand;
  technical_datas: any = [];
  technical_data: Technical_data;

  tags: any = [];

  description: any;
  selectedWorks: SelectItem[] = [];
  selectedSkills: SelectItem[] = [];
  selectedWorks2: SelectItem[];
  selectedCategories: SelectItem[] = [];

  locations: any = [];
  location: Locations;
  total: number;

  cities: Blog[];
  format1: string = "";
  format2: string = "";
  selectedCity: Blog;
  selectedClients: SelectItem[];
  yAxes: [];
  xAxes: [];
  selectedDate: Date;
  date: Date;
  works_id: any;
  category_id: any;
  public dataValues: object;
  pages: any;
  currentUser: any;
  fPairs: any;
  addForm: UntypedFormGroup;
  rows: UntypedFormArray;
  itemForm: UntypedFormGroup;
  skillsForm: UntypedFormGroup;
  skillsValues: any = [];
  data1 = [];
  chartsCount: any;
  chartsCountNone: any;
  chartsCountData: any = [];
  projects: any = [];
  project: Projects;
  dataChart: any = [];
  canvas: any;
  ctx: any;
  dataChart1: any;
  trackByFn(index, item) {
    return item.id;
  }
  subscription: Subscription;
  value: number;
  chart: [];
  page: 0;
  size: 10;
  totalPrice: number;
  project_id: any;
  private isHoveringYAxe: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private timesheetsService: TimesheetsService,
    private technicalDataService: TechnicalDataService,
    private employeesService: EmployeesService,
    private _location: Location,
    private projectsService: ProjectsService,
    private brandsService: BrandService,
    private worksService: WorksService,
    private route: ActivatedRoute
  ) {
    if (this.date) {
      this.selectedDate = new Date(this.date);
    }
    this.typeList = TYPE_LIST;
    this.status = STATUS_PRODUCTS;
    this.stateOptions = STATE_LIST;
    this.currentUser = JSON.parse(localStorage.getItem("currentUser") || "[]");
    this.value = this.totalPrice;
  }

  ngOnInit() {
    const userId = this.currentUser.user_id;

    const id = this.route.snapshot.paramMap.get("id");

    this.projectsService.getId(+id).subscribe((res) => {
      

      this.project = res;
      this.id = res.id;
      this.price = res.price;
      this.getTotalPercent(this.price);
      this.getEmployees();
      this.getTimesheet_by_project_employee(id);
      this.getTotal();
      this.getTotalPercent;
      this.getChartsCount(id);

      
    });
  }

  chartCanvas() {
    this.canvas = this.mychart.nativeElement;
    this.ctx = this.canvas.getContext("2d");
    let myChart = new Chart(this.ctx, {
      type: 'bar',
      data: {
        label: "(dist: linear)",
        datasets: [
          {
            label: "",
            data: this.data1,
            lineTension: 0,
            backgroundColor: "rgba(65, 111, 244, 0.7)",
            borderColor: "rgba(65, 111, 244, 0.8)",
          },
        ],
      },
      options: {
        events: [],
        responsive: true,

        legend: {
          labels: {
            boxWidth: 0,
          },
        },

        scales: {
          xAxes: [
            {
              type: "time",
              time: {
                tooltipFormat: "MM/yy",
                unit: "month",
                displayFormats: { month: "MM/yy" },
              },
              scaleLabel: {
                labelString: "Mese",
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                ticks: {
                  callback: value => value + (this.isHoveringYAxe ? '$' : '')
                } 
              },
              scaleLabel: {
                labelString: "Ore di lavoro",
                display: true,
              },
            },
          ],
        },
      },
    });
    myChart.update();
  }

  getTimesheet_by_project_employee(id) {
    const paramsEmployees = {
      project_id: id,
      employee_id: this.project.employee_id,
      page: 0,
      size: 10,
    };
    this.timesheetsService.list(paramsEmployees).subscribe((pData) => {
      this.timesheetsEmployee = pData;
      (error) => (this.error = error);

      
    });
  }

  getChartsCount(id) {
    const paramsCharts = {
      project_id: id,
      employee_id: this.project.employee_id,
      page: 0,
      size: 10,
    };
    this.timesheetsService.list(paramsCharts).subscribe((data) => {
      this.chartsCount = data;
      var StringifyData = JSON.stringify(this.chartsCount);
      this.chartsCount.forEach((item, index) => {
        var obj;
        obj = {
          x: new DatePipe("en-us", null).transform(item.date_from, 'dd/MM/yy'),
          y: item.hours,
        };
        this.data1.push(obj);
        this.chartCanvas();
        console.log(obj)
      });

      (error) => (this.error = error);
    });
  }

  getTotal() {
    let total = 0;
    this.timesheetsEmployee.forEach((item) => {
      total += Number(
        item.hours * this.getTechnicalDataItem(item.employee_id)?.contract
      );
    });

    return total;
  }

  getTotalPercent(price: any) {
    let total = 0;
    this.timesheetsEmployee.forEach((item) => {
      total += Number(
        item.hours * this.getTechnicalDataItem(item.employee_id)?.contract
      );
    });
    return (this.totalPrice = this.numberRoundDecimal(
      (total / this.price) * 100,
      0
    ));
  }

  getTotalHours() {
    let total = 0;
    this.timesheetsEmployee.forEach((item) => {
      total += Number(item.hours);
    });

    return total;
  }

  numberRoundDecimal(v, n) {
    return Math.round((v + Number.EPSILON) * Math.pow(10, n)) / Math.pow(10, n);
  }

  public locationsSum() {
    return this.timesheetsEmployee
      .map((data) => data.id)
      .reduce((a, b) => a + b);
  }

  getTechnicalDataItem(employee_id: string) {
    return this.employees.find((item) => item.id === employee_id);
  }

  getTechnicalDatas() {
    const paramsTechnicalData = [];
    this.technicalDataService.list(paramsTechnicalData).subscribe((pData) => {
      this.technical_datas = pData;
      (error) => (this.error = error);
    });
  }

  getBrands() {
    const paramsBrands = [];
    this.brandsService.list(paramsBrands).subscribe((pData) => {
      this.brands = pData;
      (error) => (this.error = error);
    });
  }

  getWorks() {
    const paramsWorks = [];
    this.worksService.list(paramsWorks).subscribe((pData) => {
      this.works = pData;
      (error) => (this.error = error);
    });
  }

  getEmployees() {
    const paramsEmployees = [];
    this.employeesService.list(paramsEmployees).subscribe((pData) => {
      this.employees = pData;
      (error) => (this.error = error);
    });
  }

  getCategoryItem(category_id: string, id: string) {
    return this.categories.find((item) => item.id === category_id);
  }

  getselectedCategories() {
    this.selectedCategories = this.category_id.split(",");
  }

  goback() {
    this._location.back();
  }
}
