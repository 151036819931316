import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter } from "@angular/core";
import { Products } from "src/app/models/products";
import { Products_images } from "src/app/models/products_images";
import { ProductsImagesService } from "src/app/services/products_images.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-product-item",
  templateUrl: "./product-item.component.html"
})
export class ProductItemComponent implements OnInit {
  @Input() product: Products;
  @Input() actionButtons: Boolean;


  
  @Output() deleteItem = new EventEmitter<any>();

  imgPath = environment.imageUrl;
  image: Products_images[];
  
  constructor(
    private productImagesService: ProductsImagesService
  ) {}

  ngOnInit() {

    const paramImages = {
        product_id: this.product.id,
        size: 1
      };

    this.productImagesService.list(paramImages).subscribe((pData) => {
        this.image = pData;
      });
  }


  delete(id) {
    this.deleteItem.emit(this.product.id);
  }



  



 
}
