import { Component } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractListController } from 'src/app/admin/common/abstract-list.component';
import { PARAM_CATEGORIES_PATH, PARAM_CLIENTS_PATH } from 'src/app/admin/constants/constants';
import { Clients } from 'src/app/models/clients';
import { ClientsService } from 'src/app/services/clients.service';

@Component({
  selector: 'app-manage-clients',
  templateUrl: './manage-clients.component.html'
})
export class ManageClientsComponent extends AbstractListController<Clients, any> {
  title = 'Categorie';
  nameFilter: string = '';
  surnameFilter: string = '';
  cols: any[];
  columnOptions: any[];
  selectedColumns: any[];
  client: Clients;
  productDialog: boolean = false;

  constructor(
    protected clientsService: ClientsService,
    protected confirmationService: ConfirmationService,
    router: Router,
    route: ActivatedRoute
  ) {
    super(clientsService, confirmationService, router, route, PARAM_CLIENTS_PATH);

    this.cols = [
      { field: "name", header: "Nome" },
      { field: "surname", header: "Cognome" },

      { field: "date", header: "Data di nascità" },
      { field: "phone", header: "Cellulare" },
      { field: "city", header: "Indirizzo" }
    ];

    this.columnOptions = [];
    this.selectedColumns = [];
    for (let i = 0; i < this.cols.length; i++) {
      this.columnOptions.push({ label: this.cols[i].header, value: this.cols[i] });
    }
  }
  

  edit(client: Clients) {
    this.client = { ...client };
    this.productDialog = true;
  }
  
  getFilterParams(): any {
    return {
      name: this.nameFilter,
      surname: this.surnameFilter
    };
  }

  applyFilterParams(params: any): void {
    this.nameFilter = params['name'] || '';
    this.surnameFilter = params['surname'] || '';
  }

  
  filterItem(item: Clients, filters: any): boolean {
    const nameMatch = !filters.name || item.name.toLowerCase().includes(filters.name.toLowerCase());
    const descriptionMatch = !filters.surname || item.surname.toLowerCase().includes(filters.surname.toLowerCase());
    return nameMatch && descriptionMatch;
  }

  clearFilters(): void {
    this.nameFilter = '';
    this.surnameFilter = '';
  }
}

