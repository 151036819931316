import { Component, ElementRef, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ViewChild } from '@angular/core';
import { Blog } from '../../../../models/blog';
import { Works } from '../../../../models/works';
import { UntypedFormControl } from '@angular/forms';
import { WorksService } from '../../../../services/works.service';
import { ComuniService } from '../../../../services/comuni.service';

import { MessageService, SelectItem } from "primeng/api";
import * as moment from 'moment';
import { Comuni } from 'src/app/models/comuni';
import { Tags } from 'src/app/models/tags';
import { TagsService } from 'src/app/services/tags.service';
import { TechnicalDataService } from 'src/app/services/technical_data.service';

@Component({
  selector: 'app-technical-data-form',
  templateUrl: './technical-data-form.component.html'
})
export class TechnicalDataFormComponent implements OnInit {
  @ViewChild("myInput", { static: false }) myInputVariable: ElementRef;

  pageTitle: string;
  error: string;
  uploadError: string;
  imagePath: any;
  tags: Tags;
  tag: Tags;
  skillsValues: any = [];
  comuni: Comuni;

  checked: boolean = true;
  selectedValue: string;

  categoryForm: UntypedFormGroup;
  skillsValue: string;
  cities: Blog[];
  format1: string = "";
  format2: string = "";
  selectedCity: Blog;
  selectedWorks: Works;
  selectedDate: Date;
  date: Date;
  currentUser: any;

  constructor(
    private fb: UntypedFormBuilder,
    private technicalDataService: TechnicalDataService,
    private comuniService: ComuniService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,

  ) {
    if (this.date) {
      this.selectedDate = new Date(this.date);
    }

    this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '[]');

  }

  ngOnInit() {



    const id = this.route.snapshot.paramMap.get('id');
    if (id) {


      this.pageTitle = 'Modifica Dati technici';
      this.technicalDataService.getId(+id).subscribe(
        res => {
          this.categoryForm.patchValue({
            name: res.name,
            description: res.description,
            type: res.type,
            skills: this.skillsValues,
            user_id: this.currentUser.user_id,
            id: res.id
          });
        }
      );
    } else {
      this.pageTitle = 'Create Category';

    }

    
    this.categoryForm = this.fb.group({
      id: [''],
      name: ['', Validators.required],
      description: [''],
      type: [''],
      skills: this.initSkill(),
      user_id: [this.currentUser.user_id],
    });
  }


  get name() {
    return this.categoryForm.get('name');
    return this.categoryForm.get('description');

  }

  initSkill() {
    var formArray = this.fb.array([]);
    const id = this.route.snapshot.paramMap.get("id");

    this.technicalDataService.skills(+id).subscribe(
      (res)=>{
        this.skillsValues = res;

        this.skillsValues.forEach((e)=>{
          formArray.push(this.fb.group({
            price: [e.price]
          }))
        })
      }
    )

    /*formArray.push(this.fb.group({
      qty: [''],
      price: ['']
    })) */


    return formArray;
  }


  private createSkillFormGroup(skill:any): UntypedFormGroup{
    return new UntypedFormGroup({'price':new UntypedFormControl(skill.price)})
  }

  public addSkill(skill:any){
    this.skills.push(this.createSkillFormGroup(skill));
  }


  get skills() {
    return this.categoryForm.get('skills') as UntypedFormArray;
  }


  newQuantity(): UntypedFormGroup {
    return this.fb.group({
      price: "",
    })
  }

  addQuantity() {
    this.skills.push(this.newQuantity());
  }

  removeQuantity(i:number) {
    this.skills.removeAt(i);
  }


  onSubmit() {

    const formData = new FormData();

    formData.append('name', this.categoryForm.get('name').value);
    formData.append('description', this.categoryForm.get('description').value);
    formData.append('type', this.categoryForm.get('type').value);
    formData.append('skills', JSON.stringify(this.categoryForm.get('skills').value));
    formData.append('user_id', this.categoryForm.get('user_id').value);

    const id = this.categoryForm.get('id').value;

    if (id) {
      this.technicalDataService.update(formData, +id).subscribe(
        res => {
          if (res.status == 'error') {
            this.uploadError = res.message;
          } else {
            this.messageService.add({ key: 'myKey1', severity: 'success', summary: 'Informazioni', detail: 'Salvato con sucesso' });
          }
        },
        error => this.error = error
      );
    } else {
      this.technicalDataService.create(formData).subscribe(
        res => {
          if (res.status === 'error') {
            this.uploadError = res.message;
          } else {
            this.router.navigate(['/admin/settings/technical-data']);
          }
        },
        error => this.error = error
      );
    }

  }

}
