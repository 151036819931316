import { Component } from "@angular/core";
import { ConfirmationService } from "primeng/api";
import { Router, ActivatedRoute } from "@angular/router";
import { AbstractListController } from "src/app/admin/common/abstract-list.component";
import { Products } from "src/app/models/products";
import { forkJoin, map, switchMap } from "rxjs";
import { ProductsService } from "src/app/services/products.service";
import { ProductsImagesService } from "src/app/services/products_images.service";
import { Appointments } from "src/app/models/appointments";
import { PARAM_PRODUCTS_PATH } from "../../constants/constants";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-manage-products",
  templateUrl: "./manage-products.component.html",
})
export class ManageProductsComponent extends AbstractListController<
  Products,
  any
> {
  title = "Appuntamenti";
  titleFilter: string = "";
  descriptionFilter: string = "";
  cols: { field: string; header: string }[];
  columnOptions: { label: string; value: any }[];
  selectedColumns: any[];
  productDialog: boolean = false;
  product: Products;
  appointment: Appointments;
  selectedWorks: any[];
  imgPath = environment.imageUrl;
  protected isLoading: boolean = false; // New loading indicator specific to this class

  constructor(
    protected productsService: ProductsService,
    private productsImagesService: ProductsImagesService,
    protected confirmationService: ConfirmationService,
    router: Router,
    route: ActivatedRoute
  ) {
    super(
      productsService,
      confirmationService,
      router,
      route,
      PARAM_PRODUCTS_PATH
    );

    this.cols = [
      { field: "name", header: "Nome" },
      { field: "surname", header: "Cognome" },
      { field: "date", header: "Data di nascità" },
      { field: "phone", header: "Cellulare" },
      { field: "city", header: "Indirizzo" },
    ];

    this.columnOptions = [];
    this.selectedColumns = [];
    for (let i = 0; i < this.cols.length; i++) {
      this.columnOptions.push({
        label: this.cols[i].header,
        value: this.cols[i],
      });
    }
  }

  edit(product: Products) {
    this.product = { ...product };

    if (this.product && this.product.works_id) {
      this.selectedWorks = this.product.works_id.split(",");
    }

    this.productDialog = true;
  }

  getFilterParams(): any {
    return {
      title: this.titleFilter,
      description: this.descriptionFilter,
    };
  }

  applyFilterParams(params: any): void {
    this.titleFilter = params["title"] || "";
    this.descriptionFilter = params["description"] || "";
  }

  filterItem(item: Products, filters: any): boolean {
    const nameMatch =
      !filters.title ||
      item.title.toLowerCase().includes(filters.title.toLowerCase());
    const descriptionMatch =
      !filters.description ||
      item.description
        .toLowerCase()
        .includes(filters.description.toLowerCase());
    return nameMatch && descriptionMatch;
  }

  clearFilters(): void {
    this.titleFilter = "";
    this.descriptionFilter = "";
  }
}
