import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService, ConfirmationService } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { AbstractFormComponent } from 'src/app/admin/common/abstract-edit.component';
import { PARAM_BRANDS_PATH, PARAM_CATEGORIES_PATH, STATE_LIST } from 'src/app/admin/constants/constants';
import { BrandService } from 'src/app/services/brands.service';
import { Brand } from 'src/app/models/brand';

@Component({
  selector: "app-brands-form",
  templateUrl: "./brands-form.component.html",
})
export class BrandsFormComponent extends AbstractFormComponent<Brand> implements OnInit {
  pageTitle: string;
  form: FormGroup;
  idField = 'id';
  formFields = ['id', 'name', 'description', 'is_active'];
  item$: Observable<Brand> = of(); // Initialize with an empty Observable
  stateOptions = STATE_LIST;
  service = this.brandService; // Implement the abstract service property

  constructor(
    protected fb: FormBuilder,
    private brandService: BrandService,
    messageService: MessageService,
    confirmationService: ConfirmationService,
    router: Router,
    route: ActivatedRoute,
  ) {
    super(fb, messageService, confirmationService, router, route, PARAM_BRANDS_PATH);
  }

  ngOnInit() {
    this.initializeForm();
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.pageTitle = 'Modifica Brand';
      this.loadItem(+id);
    } else {
      this.pageTitle = 'Aggiungi Brand';
    }
  }

  initializeForm() {
    this.form = this.fb.group({
      id: [''],
      name: ['', Validators.required],
      description: [''],
      is_active: ["0"]
    });
  }

  loadItem(id: number) {
    this.service.getId(id).subscribe(
      res => {
        this.form.patchValue({
          name: res.name,
          description: res.description,
          is_active: res.is_active,
          id: res.id
        });
      },
      error => this.error = error
    );
  }
}
