import { Component, NgZone, OnInit } from "@angular/core";
import { LANG_IT, PAGES } from "../constants/constants";
import { NgxSpinnerService } from "ngx-spinner";
import { SupportsService } from "src/app/services/supports.service";
import { AuthService } from "src/app/auth/auth.service";
import { formatDate } from "@angular/common";
import { Subject } from "rxjs";
import { MenuItem, PrimeNGConfig } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
})
export class AdminComponent implements OnInit {
  currentUser: any;
  currentLang: string;
  langOptions: any[] = [];
  selectedLang: any;
  submitted = false;
  returnUrl: string;
  error: {};
  loginError: string;
  username: string;
  user_id: number;
  flag = true;
  display = false;
  light = true;
  items: MenuItem[];

  password: string;
  first_name: string;
  last_name: string;
  pages: any[];

  firstName = "John";
  lastName = "Doe";
  supportsCount: any;
  myDate = formatDate(new Date(), "dd/MM/yyyy", "en");
  notify$ = new Subject();

  constructor(
    private spinner: NgxSpinnerService,
    private supportsService: SupportsService,
    private authService: AuthService,
    public primengConfig: PrimeNGConfig,
    public translate: TranslateService,
    private zone: NgZone
  ) {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser") || "[]");
   // Step 1: Retrieve stored language from localStorage (if any)
    // Step 1: Retrieve stored language from localStorage (if any)
    let savedLang = localStorage.getItem('selectedLang');

    // Step 2: If no language is stored, use the browser's default language
    if (!savedLang) {
      const browserLang = this.translate.getBrowserLang() || 'en'; // Fallback to 'en' if browserLang is not available
      savedLang = browserLang;
      localStorage.setItem('selectedLang', savedLang); // Store the browser language
    }

    // Step 3: Set the current language based on the stored or browser language
    this.currentLang = savedLang;

    // Step 4: Add supported languages to ngx-translate
    this.translate.addLangs(['en', 'it']);
    this.translate.setDefaultLang(this.currentLang);
    this.translate.use(this.currentLang); // Use the selected or stored language

    // Step 5: Populate the dropdown options for languages
    this.langOptions = this.translate.getLangs().map(lang => ({
      label: lang.toUpperCase(), // Display language in uppercase
      value: lang                // Store the language code as value
    }));

    this.pages = PAGES;

    localStorage.setItem(
      "expiredDate",
      this.addMinutes(new Date(), 60).getTime().toString()
    );
   
  }

  ngOnInit() {
    this.items = [
      {
        label: "Dashboard",
        icon: "pi pi-pw pi-inbox",
        routerLink: "/admin",
      },
      {
        label: "Immobiliari",
        icon: "pi pi-fw pi-home",
        routerLink: "/admin/products/",
      },

      {
        label: "Richieste",
        icon: "pi pi-fw pi-comments",
        routerLink: "/admin/requests/",
      },
      {
        label: "Progetti",
        icon: "pi pi-fw pi-clone",
        routerLink: "/admin/projects/",
      },
      {
        label: "Fatturazione",
        icon: "pi pi-fw pi-credit-card",
        routerLink: "/admin/billings/",
      },
      {
        label: "Appuntamenti",
        icon: "pi pi-fw pi-calendar-plus",
        routerLink: "/admin/appointments/",
      },
      {
        label: "Calendario",
        icon: "pi pi-fw pi-calendar",
        routerLink: "/admin/calendar/",
      },
      {
        label: "Clienti",
        icon: "pi pi-fw pi-user",
        routerLink: "/admin/clients/",
      },

      {
        label: "Dipendenti",
        icon: "pi pi-fw pi-user",
        routerLink: "/admin/employees/",
      },

   

      {
        label: "Configurazioni",
        icon: "pi pi-fw pi-cog",
        routerLink: "/admin/settings/",
      },

      {
        label: "Logout",
        icon: "pi pi-sign-in",
        command: (event) => {
          this.logout();
        },
      },
    ];

    this.notify$.subscribe(() => {
      localStorage.removeItem("expiredDate");
      this.logout();
    });

    

    setTimeout(() => {
      
    }, 500);
  }

  public get getInitialsBgColor(): string {
    var s = 30;
    var l = 47;

    if (this.firstName) {
      s = this.getSaturationByName(this.firstName, this.lastName);
      l = this.getLightnessByName(this.firstName, this.lastName);
    }

    var color = this.stringToHslColor(
      this.firstName + " " + this.lastName,
      l,
      s
    );
    return color;
  }

  addMinutes(date, minutes) {
    return new Date(date.getTime() + minutes * 60000);
  }


  logout() {
    this.authService.logout();
  }

  onLanguageChange(): void {
    // Update the translation service to use the new language
    this.translate.use(this.currentLang);

    // Update localStorage with the newly selected language
    localStorage.setItem('selectedLang', this.currentLang);
  }

  getInitialsTextColor(firstName: string, lastName: string): string {
    var s = this.getSaturationByName(firstName, lastName);
    var l = this.getLightnessByName(firstName, lastName);
    if (l >= 40) return "black";
    else return "white";
  }
  getLightnessByName(firstName: string, lastName: string): number {
    var num =
      firstName.toUpperCase().charCodeAt(0) +
      lastName.toUpperCase().charCodeAt(0);
    return this.scaleBetween(num, 5, 95, 65 * 2, 97 * 2);
  }
  getSaturationByName(firstName: string, lastName: string): number {
    var num =
      firstName.toUpperCase().charCodeAt(0) +
      lastName.toUpperCase().charCodeAt(0);
    return this.scaleBetween(num, 30, 75, 65 * 2, 97 * 2);
  }
  scaleBetween(unscaledNum, minAllowed, maxAllowed, min, max): number {
    var res =
      ((maxAllowed - minAllowed) * (unscaledNum - min)) / (max - min) +
      minAllowed;
    return parseInt(res);
  }
  stringToHslColor(str: string, saturation: number, lightness: number): string {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var h = hash % 360;
    return "hsl(" + h + ", " + saturation + "%, " + lightness + "%)";
  }
}
